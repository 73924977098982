import { useState } from "react";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import useStore from "../../store/useStore";
import SessionHabitatForm, { SessionHabitatFormForm } from "./HabitatForm";

const SessionHabitatTabs = () => {
  const { session } = useStore((state) => state);
  const [value, setValue] = useState<string>("0");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <TabContext value={value}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <TabList onChange={handleChange} aria-label="session habitat tabs">
          {session?.form.habitats.map(
            (habitat: SessionHabitatFormForm, index: number) => (
              <Tab
                key={index}
                label={`Habitat ${index + 1}`}
                value={String(index)}
              />
            )
          )}
        </TabList>
      </Box>
      {session?.form.habitats.map(
        (habitat: SessionHabitatFormForm, index: number) => (
          <TabPanel sx={{ p: 0 }} key={index} value={String(index)}>
            <SessionHabitatForm form={habitat} />
          </TabPanel>
        )
      )}
    </TabContext>
  );
};

export default SessionHabitatTabs;
