import { FC } from "react";
import { fr } from "date-fns/locale";
import { DateRangePicker } from "@bjarkehs/react-nice-dates";
import { Stack, TextField, GlobalStyles } from "@mui/material";
import "@bjarkehs/react-nice-dates/build/style.css";

export interface CommonsDatePickerProps {
  startDate?: Date;
  endDate?: Date;
  onStartDateChange?(date: Date | null): void;
  onEndDateChange?(date: Date | null): void;
}

const popoverGlobalStyles = (
  <GlobalStyles
    styles={{
      ".nice-dates-popover": {
        maxWidth: "400px",
      },
    }}
  />
);

const CommonsDatePicker: FC<CommonsDatePickerProps> = ({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
}) => {
  return (
    <>
      {popoverGlobalStyles}
      <DateRangePicker
        startDate={startDate}
        endDate={endDate}
        onStartDateChange={onStartDateChange}
        onEndDateChange={onEndDateChange}
        locale={fr}
      >
        {({ startDateInputProps, endDateInputProps }) => (
          <Stack
            sx={{ width: "100%" }}
            direction={{ xs: "column", md: "row" }}
            className="date-range"
            gap={1}
          >
            <TextField
              fullWidth
              inputProps={{ ...startDateInputProps, placeholder: "jj/mm/a" }}
            />
            <span className="date-range_arrow" />
            <TextField
              fullWidth
              inputProps={{ ...endDateInputProps, placeholder: "jj/mm/a" }}
              placeholder="End date"
            />
          </Stack>
        )}
      </DateRangePicker>
    </>
  );
};

export default CommonsDatePicker;
