import { FC } from "react";
import {
  ArrayField,
  DateField,
  FunctionField,
  Show,
  SimpleShowLayout,
  TextField,
  WithListContext,
} from "react-admin";
import { AdminJsonFieldValue } from "./AdminJsonFieldValue";
import { Box, Typography } from "@mui/material";
import { HABITATS } from "../site/HabitatTab";
import { translations } from "../../intl/translations";
import { FIELDS } from "../site/ManagementPractices";

export const AdminSiteShow: FC = (props) => {
  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="name" label="Nom" />
        <TextField source="id" label="ID" />
        <TextField source="owner.username" label="Owner" />
        <TextField source="uuid" label="UUID" />
        <DateField source="created_date" label="Créé le" />
        <TextField source="department" label="Département" />
        <TextField source="area" />
        <FunctionField
          label="Geom"
          render={(record?: any) => (
            <AdminJsonFieldValue
              value={record?.geom}
              maxWidth={800}
              noWrap={false}
            />
          )}
        />
        <ArrayField source="shareds" label="Invité" perPage={9999999}>
          <WithListContext
            render={(params) => (
              <span style={{ cursor: "pointer" }}>
                {params.data.map((shared) => shared.group?.name).join(", ")}
              </span>
            )}
          />
        </ArrayField>
        <FunctionField
          label="Centroïd"
          render={(record?: any) => {
            return (record?.centroid?.coordinates || []).map(
              (value: number, i: number) => {
                return <Typography key={`${value}${i}`}>{value}</Typography>;
              }
            );
          }}
        />

        <FunctionField
          label="Habitats"
          render={(record?: any) =>
            record.habitats?.map((habitat: any, index: number) => {
              const habitatKey = habitat["0"];
              const habitatConf = HABITATS.find((conf) => {
                return conf.key === habitatKey;
              });

              return (
                <Box key={`${habitatKey}_${index}`} mb={1}>
                  <Typography fontWeight="bold">Habitat {index + 1}</Typography>
                  <Box>
                    <Typography component="span" display={"inline-block"}>
                      Valeur 1 :
                    </Typography>
                    <Typography
                      component="span"
                      display={"inline-block"}
                      ml={1}
                    >
                      {`${habitatKey}.
                      ${
                        (translations.fr.translation.habitats as any)[
                          `${habitatKey}1`
                        ]
                      }`}
                    </Typography>
                  </Box>
                  {Boolean(habitatKey) &&
                    habitatConf?.nbRowsInCols.map((nbRowsInCol, colIndex) => {
                      const colNum = colIndex + 2;
                      const rowKey = !Boolean(nbRowsInCol)
                        ? null
                        : habitat[colIndex + 1];

                      return (
                        <Box key={habitatKey + colIndex}>
                          <Typography component="span" display={"inline-block"}>
                            Valeur {colNum} :
                          </Typography>
                          <Typography
                            component="span"
                            display={"inline-block"}
                            ml={1}
                          >
                            {rowKey === null
                              ? "Aucune valeur à sélectionner"
                              : `${rowKey}. ${
                                  (translations.fr.translation.habitats as any)[
                                    `${habitatKey}${colNum}`
                                  ][rowKey]
                                }`}
                          </Typography>
                        </Box>
                      );
                    })}
                </Box>
              );
            })
          }
        />
        <FunctionField
          label="Pratiques de gestion"
          render={(record?: any) => (
            <Box>
              {FIELDS.map((field) => (
                <Box key={field.name}>
                  <Typography component="b">{field.label}</Typography>
                  <Typography component="span" ml={2}>
                    {
                      field.options?.find(
                        (option) =>
                          option.value ==
                          record?.management_practices?.[field.name]
                      )?.label
                    }
                  </Typography>
                </Box>
              ))}
            </Box>
          )}
        />
      </SimpleShowLayout>
    </Show>
  );
};
