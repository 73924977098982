import { FC, MutableRefObject, useEffect, useState } from "react";
import LayoutMap from "../components/layout/Map";
import MapBase from "../components/map/Base";
import useStore from "../store/useStore";
import SessionInfo from "../components/session/Info";
import { useParams } from "react-router-dom";
import { Circle } from "react-leaflet";
import { LatLngExpression } from "leaflet";

const SessionPage: FC = () => {
  const { session, sessionGet, groupGetAll } = useStore((state) => state);
  const params = useParams();
  const [mapRefs, setMapRefs] = useState<{
    map: L.Map;
    featureGroup: MutableRefObject<L.FeatureGroup>;
  }>();

  useEffect(() => {
    if (params.sessionId) {
      const fetchData = async () => {
        await groupGetAll();
        await sessionGet(params.sessionId!);
      };
      fetchData();
    }
  }, [params.sessionId]);

  useEffect(() => {
    const featureGroup = mapRefs?.featureGroup?.current;

    if (featureGroup && featureGroup.getBounds().isValid()) {
      mapRefs?.map.fitBounds(featureGroup.getBounds());
    }
  }, [mapRefs?.map, mapRefs?.featureGroup]);

  if (params.sessionId !== session?.uuid) {
    return null;
  }

  const handleOnInit = (
    map: L.Map,
    featureGroup: MutableRefObject<L.FeatureGroup>
  ) => {
    setMapRefs({
      map,
      featureGroup,
    });
  };

  return session ? (
    <LayoutMap
      map={
        <MapBase onInit={handleOnInit}>
          <Circle
            center={session?.geom?.coordinates.reverse() as LatLngExpression}
            radius={10}
          />
        </MapBase>
      }
    >
      <SessionInfo />
    </LayoutMap>
  ) : null;
};

export default SessionPage;
