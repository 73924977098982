import { FC } from "react";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export interface DialogState {
  open: boolean;
  loading: boolean;
}

export interface CommonAlertDialogProps {
  title: string;
  content: string[];
  agreeLabel: string;
  disagreeLabel?: string;
  loading?: boolean;
  open: boolean;
  onAgree?(): void;
  onDisagree?(): void;
}

const CommonAlertDialog: FC<CommonAlertDialogProps> = ({
  title,
  content,
  agreeLabel,
  disagreeLabel,
  open = false,
  loading = false,
  onAgree,
  onDisagree,
}) => {
  const handleAgree = () => {
    onAgree && onAgree();
  };
  const handleDisagree = () => {
    onDisagree && onDisagree();
  };

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" color="error">
          {title}
        </DialogTitle>
        <DialogContent>
          {content.map((item, key) => (
            <DialogContentText key={key}>{item}</DialogContentText>
          ))}
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={() => handleDisagree()}>
            {disagreeLabel}
          </Button>
          <LoadingButton
            loading={loading}
            autoFocus
            variant="contained"
            color="error"
            onClick={() => handleAgree()}
          >
            {agreeLabel}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CommonAlertDialog;
