import {
  TextField,
  CircularProgress,
  Paper,
  Autocomplete,
} from "@mui/material";
import { useState, useEffect, Fragment } from "react";

export interface GeoGouvCommune {
  nom: string;
  population: number;
  departement: {
    code: string;
    nom: string;
  };
  centre: {
    type: string;
    coordinates: [number, number];
  };
}

export interface MapSearchBarProps {
  onChange?(option: GeoGouvCommune): void;
}

const fetchData = async (
  name: string,
  fields: string = "nom,population,centre,departement"
) => {
  const url = `https://geo.api.gouv.fr/communes?nom=${name}&fields=${fields}&format=json&geometry=centre`;
  const response = await fetch(url);
  const json = (await response.json()) as GeoGouvCommune[];

  const sort = (a: GeoGouvCommune, b: GeoGouvCommune) => {
    if (a.population === b.population) return 0;
    else if (a.population - b.population > 0) return -1;
    else return 1;
  };

  const options = json
    .filter((item) => item.centre && item.centre.coordinates)
    .sort(sort);

  return options;
};

const MapSearchBar: React.FC<MapSearchBarProps> = ({ onChange }) => {
  const [value, setValue] = useState<GeoGouvCommune>({} as GeoGouvCommune);
  const [options, setOptions] = useState<GeoGouvCommune[]>(
    [] as GeoGouvCommune[]
  );
  const [loading, setLoading] = useState(false);

  return (
    <Paper>
      <Autocomplete
        freeSolo
        size="small"
        options={options}
        loading={loading}
        value={value}
        style={{ width: 300 }}
        onChange={(_event, newValue) => {
          if (newValue && typeof newValue !== "string") {
            setValue(newValue);
            onChange && onChange(newValue);
          }
        }}
        onInputChange={async (_event, newInputValue) => {
          if (newInputValue.length < 3 || newInputValue === "") {
            return;
          }

          setLoading(true);
          const newOptions = await fetchData(newInputValue);
          setLoading(false);
          setOptions(newOptions);
        }}
        getOptionLabel={(option) => {
          if (typeof option === "string" || !option.departement) {
            return "";
          }

          return `${option.nom} (${option.departement.nom})`;
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            value={value}
            label="Chercher une commune"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </Paper>
  );
};

export default MapSearchBar;
