import Calendar from "react-calendar";
import { Paper, GlobalStyles } from "@mui/material";
import React, { FC } from "react";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import "./CalendarMoon.css";
import { getNewMoon } from "../../lib/getMoonPhase";

interface CalendarProps {
  prevLabel?: string | null | ReactJSXElement;
  nextLabel?: string | null | ReactJSXElement;
  showNeighboringMonth?: boolean;
  onClick?: () => void;
  activeStartDate?: Date;
  displayWeeks?: boolean;
  showFixedNumberOfWeeks?: boolean;
  borderTiles?: boolean;
}

function tileClassName({ date, view }: any) {
  const newDate = new Date(date);

  if (![4, 5, 6, 7, 8].includes(newDate.getMonth())) {
    return "CalendarMoon-forbidden";
  }

  if (view === "month") {
    switch (getNewMoon(new Date(date))) {
      case 0:
        return "newMoon";
      case 1:
        return "newMoonOneDay";
      case 2:
        return "newMoonTwoDays";
      default:
        return "CalendarMoon-forbidden2";
    }
  }
  return null;
}

const globalStyles = (
  <GlobalStyles
    styles={{
      ".react-calendar__month-view__weekdays__weekday": {
        display: "none",
      },
      ".react-calendar__navigation button": {
        pointerEvents: "none",
      },
    }}
  />
);

const BorderGlobalStyles = (
  <GlobalStyles
    styles={{
      ".react-calendar__tile": {
        borderRadius: "50px",
        border: "5px solid white",
        padding: "5px",
      },
    }}
  />
);

const navigationLabel = ({ date, locale, view, label }: any) => {
  if (view === "month") {
    if (date.getFullYear() === new Date().getFullYear()) {
      return `${date.toLocaleString(locale, { month: "long" })}`;
    }
    return `${date.toLocaleString(locale, { month: "long", year: "numeric" })}`;
  }
  if (view === "year") {
    return `${date.toLocaleString(locale, { year: "numeric" })}`;
  }
  return label;
};

const CalendarMoon: FC<CalendarProps> = ({
  prevLabel,
  nextLabel,
  showNeighboringMonth,
  onClick,
  activeStartDate,
  displayWeeks = false,
  showFixedNumberOfWeeks = false,
  borderTiles = false,
}) => {
  return (
    <Paper onClick={onClick}>
      {!displayWeeks && globalStyles}
      {borderTiles && BorderGlobalStyles}
      <Calendar
        activeStartDate={activeStartDate ?? undefined}
        maxDetail="month"
        minDetail="year"
        showNeighboringMonth={showNeighboringMonth ?? true}
        next2Label={null}
        nextLabel={nextLabel}
        prevLabel={prevLabel}
        prev2Label={null}
        tileClassName={tileClassName}
        navigationLabel={navigationLabel}
        showFixedNumberOfWeeks={showFixedNumberOfWeeks}
      />
    </Paper>
  );
};
export default CalendarMoon;
