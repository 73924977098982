import { FC, ReactNode } from "react";
import useStore from "../../store/useStore";
import ContainerTitle from "../commons/ContainerTitle";
import CommonsListItemCoordinates from "../commons/ListItemCoordinates";
import CommonsMetadataList, {
  RecordMetadataItem,
} from "../commons/MetadataList";
import IsNotVisibleAlert from "../commons/IsNotVisibleAlert";

const SiteMetadata: FC = () => {
  const { site, user, groups } = useStore((state) => state);

  if (!site) {
    return null;
  }

  const isOwner = site.owner_id === user?.user.sub;
  const items: (RecordMetadataItem | ReactNode)[] = [
    {
      primary: "Nom du site",
      secondary: site.name,
      visible: isOwner,
    },
    {
      primary: "Invités",
      secondary: !site.shared_info?.length
        ? "Aucun organisme invité à visualiser ce site"
        : site.shared_info,
      visible: isOwner,
    },
    {
      primary: "Organisme",
      secondary: groups.find((g) => g.id === site.group_id)?.name,
    },
    {
      primary: "Utilisateur",
      secondary: site.owner_info,
    },
    {
      primary: "Département",
      secondary: site.department,
    },
    {
      primary: "Date de création",
      secondary: new Date(site.created_date).toLocaleString(),
    },
    {
      primary: "Aire calculée (m²)",
      secondary: site.area,
    },
    <CommonsListItemCoordinates
      lat={site.centroid.coordinates[1]}
      long={site.centroid.coordinates[0]}
    />,
  ];

  return (
    <ContainerTitle title="Informations sur le site">
      <IsNotVisibleAlert site={site} user={user} />
      <CommonsMetadataList items={items} />
    </ContainerTitle>
  );
};

export default SiteMetadata;
