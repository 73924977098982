import { FC, useState, useEffect, ChangeEvent } from "react";
import {
  IconButton,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Box,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from "@mui/icons-material/Delete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import useStore from "../../store/useStore";
import ContainerTitle from "../commons/ContainerTitle";
import { SiteRead } from "../../client";
import { useNavigate } from "react-router-dom";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { SiteReadWithSessionUUID } from "../../client/models/SiteReadWithSessionUUID";

interface SiteListProps {
  onSelected?(site: SiteRead): void;
  onDeleted?(site: SiteRead): void;
}

const emptyFn = () => {};

const SiteList: FC<SiteListProps> = ({
  onSelected = emptyFn,
  onDeleted = emptyFn,
}) => {
  const { sites, sitesGet, sitesLoading, user } = useStore((state) => state);
  const navigate = useNavigate();
  const [currentPage, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedRow, setSelectedRow] = useState("");

  const handleRowsPerPage = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    sitesGet([`limit(${rowsPerPage}, ${currentPage * rowsPerPage})`]);
  }, [currentPage, sitesGet, rowsPerPage]);

  return (
    <Box sx={{ height: { md: "calc(100vh - 64px)", xs: "calc(50vh - 64px)" } }}>
      <ContainerTitle title="SITES">
        <TableContainer component={Paper} variant="outlined">
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Nom des sites</TableCell>
                <TableCell>Sessions</TableCell>
                <TableCell>Date de création</TableCell>
                <TableCell>Département</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {sitesLoading &&
                Array(rowsPerPage)
                  .fill("")
                  .map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Skeleton animation="wave" />
                      </TableCell>
                      <TableCell>
                        <Skeleton animation="wave" />
                      </TableCell>
                      <TableCell>
                        <Skeleton animation="wave" />
                      </TableCell>
                      <TableCell>
                        <Skeleton animation="wave" />
                      </TableCell>
                      <TableCell>
                        <Skeleton animation="wave" />
                      </TableCell>
                      <TableCell>
                        <Skeleton animation="wave" />
                      </TableCell>
                      <TableCell>
                        <Skeleton animation="wave" />
                      </TableCell>
                    </TableRow>
                  ))}
              {sites.records.map((site: SiteReadWithSessionUUID) => {
                const isOwner = site?.owner_id === user?.user?.sub;
                return (
                  <TableRow key={site.uuid} hover={true}>
                    <TableCell>
                      <Stack direction={"row"}>
                        <Tooltip title="+ d'infos">
                          <IconButton
                            onClick={() => {
                              navigate(`/sites/${site.uuid}`);
                            }}
                          >
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="localiser">
                          <IconButton
                            color={
                              selectedRow === site.uuid ? "primary" : "default"
                            }
                            onClick={() => {
                              onSelected(site);
                              setSelectedRow(site.uuid);
                            }}
                          >
                            <LocationOnIcon />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </TableCell>
                    <TableCell>{site.name}</TableCell>
                    <TableCell>{site?.sessions?.length ?? "0"}</TableCell>
                    <TableCell>
                      {new Date(site.created_date).toLocaleString()}
                    </TableCell>
                    <TableCell>{site.department}</TableCell>
                    <TableCell>
                      {isOwner && (
                        <Tooltip title="supprimer le site">
                          <IconButton onClick={() => onDeleted(site)}>
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
              <TableRow>
                <TablePagination
                  labelRowsPerPage={"Sites par page"}
                  onPageChange={(
                    event: React.MouseEvent<HTMLButtonElement> | null,
                    page: number
                  ) => {
                    setPage(page);
                  }}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "Sites par page",
                    },
                    native: true,
                  }}
                  colSpan={6}
                  count={sites.metadata.total}
                  rowsPerPageOptions={[5, 10, 25, { label: "Tous", value: -1 }]}
                  rowsPerPage={rowsPerPage}
                  page={currentPage}
                  onRowsPerPageChange={handleRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </ContainerTitle>
    </Box>
  );
};

export default SiteList;
