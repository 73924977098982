import { SetState, GetState } from "zustand";
import { GlobalState } from "./useStore";
import { StatisticsService, GlobalStatsModel } from "../client";

export interface StatsState {
    stats: GlobalStatsModel | undefined
    statsGlobal: boolean;
    statsGetAll(): void;
    statsGlobalSet(toggleStatsGlobal: boolean): void;
}

const createStats = (
    set: SetState<GlobalState>,
    get: GetState<GlobalState>
) => ({
    stats: undefined,
    statsGlobal: true,
    statsGlobalSet: (toggleStatsGlobal: boolean) => {
        set(() => ({ statsGlobal: toggleStatsGlobal }));
    },
    statsGetAll: async () => {
        const stats = await StatisticsService.statisticsStatisticsGet();
        set(() => ({ stats }));
    },
});

export default createStats;
