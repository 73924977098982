import { Outlet } from "react-router-dom";
import { Box, Container } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FC, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { SxProps, Theme } from "@mui/system";
import useStore from "../../store/useStore";
import { useSnackbar } from "notistack";
const BodyContainer = styled("div")({
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
});

interface LayoutBaseProps {
  withoutFooter?: boolean;
  smallLogo?: boolean;
  container?: boolean;
  sx?: SxProps<Theme>;
}

const LayoutBase: FC<LayoutBaseProps> = ({
  withoutFooter = false,
  smallLogo = false,
  container = true,
  sx,
}) => {
  const { snackbar } = useStore((state) => state);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    snackbar?.alerts?.map((alert) => {
      enqueueSnackbar(alert.message, {
        autoHideDuration: alert?.autoHideDuration ?? 4000,
        variant: alert.severity,
        persist: alert?.persist ?? false,
      });
    });
  }, [snackbar?.alerts]);

  return (
    <BodyContainer>
      <Header smallLogo={smallLogo} />
      {container ? (
        <Container
          sx={{
            flex: 1,
            padding: "2rem",
            ...sx,
          }}
        >
          <Outlet />
        </Container>
      ) : (
        <Box
          sx={{
            flex: 1,
            padding: 0,
            ...sx,
          }}
        >
          <Outlet />
        </Box>
      )}
      {!withoutFooter && <Footer />}
    </BodyContainer>
  );
};

export default LayoutBase;
