import { MouseEvent, useEffect, useState } from "react";
import { Alert, Box, Button, Stack, Tab, Tooltip } from "@mui/material";
import { TabContext, TabList } from "@mui/lab";
import useStore from "../../store/useStore";
import { useFieldArray, useForm } from "react-hook-form";
import { SitesService } from "../../client";
import { HabitatTab } from "./HabitatTab";
import { Add, Close } from "@mui/icons-material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const validationSchema = yup.object().shape({
  habitats: yup
    .array()
    .of(yup.array().of(yup.string().required("Ce champs est requis."))),
});

const HabitatTabs = () => {
  const { site, setSite, user } = useStore((state) => state);
  const [curTab, setCurTab] = useState<string>("0");
  const [isOwner, setIsOwner] = useState<boolean>(false);
  const { snackbarSetAlerts } = useStore((state) => state);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setCurTab(newValue);
  };

  const formApi = useForm({
    resolver: yupResolver(validationSchema),
  });
  const {
    fields: habitats,
    append,
    remove,
  } = useFieldArray({
    control: formApi.control,
    name: "habitats",
  });

  useEffect(() => {
    if (formApi.formState.errors?.habitats) {
      snackbarSetAlerts([
        {
          message: " Le formulaire est incomplet",
          severity: "error",
          autoHideDuration: 10000,
        },
      ]);
    }
  }, [formApi.formState.errors]);

  useEffect(() => {
    if (site?.habitats) {
      formApi.reset({
        habitats: site?.habitats,
      });
    }
  }, [site?.habitats]);

  useEffect(() => {
    setIsOwner(site?.owner_id === user?.user?.sub);
  }, [site?.owner_id, user?.user?.sub]);

  const onSubmit = async () => {
    if (site?.uuid) {
      try {
        const values = formApi.getValues();
        const siteUpdated = await SitesService.updateSiteSitesSiteUuidPatch(
          site.uuid,
          {
            habitats: values.habitats,
          }
        );
        setSite(siteUpdated);
      } catch (e) {
      } finally {
        snackbarSetAlerts([
          {
            message:
              "Formulaire envoyé et complet, merci pour votre contribution",
            severity: "success",
          },
        ]);
      }
    }
  };

  return (
    <form onSubmit={formApi.handleSubmit(onSubmit)}>
      <Stack>
        <TabContext value={curTab}>
          <Stack
            direction="row"
            alignItems="center"
            sx={{ borderBottom: 1, borderColor: "divider" }}
          >
            <Box
              sx={{
                marginRight: "2px",
              }}
            >
              {habitats.length > 0 ? (
                <Tooltip title="Ajouter un habitat" placement="top" arrow>
                  <Button
                    size="small"
                    color="secondary"
                    variant="contained"
                    sx={{
                      minWidth: "auto",
                      padding: "2px",
                    }}
                    onClick={() => {
                      append([[""]]);
                    }}
                  >
                    <Add />
                  </Button>
                </Tooltip>
              ) : (
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => {
                    append([[""]]);
                  }}
                  startIcon={<Add />}
                >
                  Ajouter un habitat
                </Button>
              )}
            </Box>
            <TabList
              onChange={handleTabChange}
              aria-label="site habitat tabs"
              TabIndicatorProps={{
                sx: {
                  background: (formApi.formState.errors?.habitats as any)?.[
                    curTab
                  ]
                    ? "red"
                    : undefined,
                },
              }}
            >
              {habitats.map((_: any, index: number) => (
                <Tab
                  key={_.id}
                  value={String(index)}
                  sx={{
                    pr: 0,
                    color: (formApi.formState.errors?.habitats as any)?.[index]
                      ? "red !important"
                      : "inherit",
                    background: (formApi.formState.errors?.habitats as any)?.[
                      index
                    ]
                      ? "#FF000033 !important"
                      : "inherit",
                  }}
                  label={
                    <span>
                      {`Habitat ${index + 1}`}
                      {
                        index > 0 &&
                        <Box
                          component="span"
                          sx={{
                            verticalAlign: "middle",
                            color: "#F00",
                            display: "inline-block",
                            width: "20px",
                            height: "20px",
                            borderRadius: "50%",
                            border: "1px solid transparent",
                            marginLeft: "2px",
                            "&:hover": {
                              borderColor: "#F00",
                              fontWeight: "bold",
                            },
                          }}
                          onClick={(e: MouseEvent<HTMLSpanElement>) => {
                            e.stopPropagation();
                            if (
                              habitats.length > 1 &&
                              Number(curTab) >= habitats.length - 1
                            ) {
                              setCurTab(String(Number(curTab) - 1));
                            }
                            remove(index);
                          }}
                        >
                          <Close
                            fontSize="small"
                            style={{ transform: "translate(-1px, -1px)" }}
                          />
                        </Box>
                      }
                    </span>
                  }
                />
              ))}
            </TabList>
          </Stack>
          {habitats?.map((_, index: number) => (
            <HabitatTab key={_.id} formApi={formApi} index={index} />
          ))}
        </TabContext>
        <Stack mt={3}>
          {isOwner ? (
            <Button
              type="submit"
              variant="contained"
              disabled={habitats.length < 1}
            >
              Envoyer
            </Button>
          ) : (
            <Alert variant="filled" severity="info" sx={{ color: "#000000DE" }}>
              Vous n'avez pas les droits d'édition sur ce site
            </Alert>
          )}
        </Stack>
      </Stack>
    </form>
  );
};

export default HabitatTabs;
