import { FC } from "react";
import {
  Stack,
  Typography,
  Skeleton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import useStore from "../../store/useStore";
import Thumbnail from "./Thumbnail";
import { ImageRead } from "../../client";
import InfiniteScroll from "react-infinite-scroll-component";

const styles = {
  container: {
    maxHeight: "650px",
    minWidth: "200px",
    overflow: "auto",
    p: 1,
  },
  noData: {
    height: { md: "650px" },
    minWidth: "200px",
    bgcolor: "primary.light",
    borderRadius: "5px",
    p: 2,
    color: "primary.dark",
  },
  loader: {
    mt: { md: 2, xs: 0 },
    ml: { xs: 2, md: 0 },
  },
};

const Loader: FC = () => {
  return (
    <>
      {Array(5)
        .fill("")
        .map((item, index) => (
          <Skeleton key={index} variant="rectangular" animation="wave">
            <Thumbnail image={undefined} />
          </Skeleton>
        ))}
    </>
  );
};

const ListThumbnail: FC = () => {
  const {
    images,
    imageSelected,
    imagesGetAll,
    setInfiniteScroll,
    imageHasMore,
    imagesLoading,
    infiniteScroll,
  } = useStore((state) => state);
  const theme = useTheme();
  const responsive = useMediaQuery(theme.breakpoints.down("md"));

  const loadMore = async () => {
    setInfiniteScroll(true);
    await imagesGetAll([`${images.metadata.next}`]);
    setInfiniteScroll(false);
  };

  if (!imagesLoading && images.records.length === 0)
    return (
      <Stack sx={styles.noData} justifyContent="center">
        <Typography align="center" color="primary" variant="h6">
          Aucune photo à afficher
        </Typography>
      </Stack>
    );
  return (
    <Stack
      direction={{ xs: "row", md: "column" }}
      sx={styles.container}
      id="scrollableDiv"
    >
      <InfiniteScroll
        style={{ display: responsive ? "flex" : "" }}
        dataLength={images.records.length}
        next={loadMore}
        hasMore={imageHasMore}
        loader={
          <Stack
            direction={{ xs: "row", md: "column" }}
            spacing={2}
            sx={styles.loader}
          >
            <Loader />
          </Stack>
        }
        scrollableTarget="scrollableDiv"
      >
        <Stack spacing={2} direction={{ xs: "row", md: "column" }}>
          {imagesLoading && !infiniteScroll && <Loader />}
          {images?.records.map((image: ImageRead) => (
            <Thumbnail
              active={imageSelected?.checksum === image.checksum}
              link={false}
              key={image.checksum}
              image={image}
              caption={true}
            />
          ))}
        </Stack>
      </InfiniteScroll>
    </Stack>
  );
};
export default ListThumbnail;
