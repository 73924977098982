import { FC, ReactElement } from "react";
import { styled } from "@mui/material/styles";
import { Container } from "@mui/material/";

interface LayoutGalleryProps {
  filters: ReactElement;
  images: ReactElement;
  viewer: ReactElement;
  metadatas: ReactElement;
  actions: ReactElement;
}

const LayoutGalleryContainer = styled(Container)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(12, 1fr)",
  columnGap: "1rem",
  rowGap: "1rem",
}));

const LayoutGalleryFilters = styled("div")(({ theme }) => ({
  gridColumn: "1/13",
  alignSelf: "end",
}));

const LayoutGalleryImages = styled("div")(({ theme }) => ({
  gridColumn: "1/2",
  [theme.breakpoints.down("md")]: {
    gridColumn: "1/13",
    overflow: "hidden",
  },
}));

const LayoutGalleryViewer = styled("div")(({ theme }) => ({
  gridColumn: "2/9",
  [theme.breakpoints.down("md")]: {
    gridColumn: "1/13",
  },
}));

const LayoutGalleryMetadatas = styled("div")(({ theme }) => ({
  gridColumn: "9/13",
  [theme.breakpoints.down("md")]: {
    gridColumn: "1/13",
  },
}));

const LayoutGalleryActions = styled("div")(({ theme }) => ({
  gridColumn: "9/13",
  [theme.breakpoints.down("md")]: {
    gridColumn: "1/13",
  },
}));

const LayoutGallery: FC<LayoutGalleryProps> = ({
  filters,
  images,
  viewer,
  metadatas,
  actions,
}) => {
  return (
    <LayoutGalleryContainer>
      <LayoutGalleryFilters>{filters}</LayoutGalleryFilters>
      <LayoutGalleryImages>{images}</LayoutGalleryImages>
      <LayoutGalleryViewer>{viewer}</LayoutGalleryViewer>
      <LayoutGalleryMetadatas>{metadatas}</LayoutGalleryMetadatas>
      <LayoutGalleryActions>{actions}</LayoutGalleryActions>
    </LayoutGalleryContainer>
  );
};

export default LayoutGallery;
