/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { KeycloakGroup } from '../models/KeycloakGroup';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class GroupsService {

    /**
     * All Groups
     * @returns KeycloakGroup Successful Response
     * @throws ApiError
     */
    public static allGroupsGroupsGet(): CancelablePromise<Array<KeycloakGroup>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/groups/',
        });
    }

}