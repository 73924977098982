import { FC } from "react";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
} from "@mui/material";
import ListAltIcon from "@mui/icons-material/ListAlt";
import ContainerTitle from "../commons/ContainerTitle";
import { useNavigate } from "react-router-dom";
import { SessionRead } from "../../client";
import useStore from "../../store/useStore";

interface LastSessionsProps {
  sessions?: SessionRead[];
  number?: number;
}

const LastSessions: FC<LastSessionsProps> = ({ sessions = [], number = 0 }) => {
  const { sessionsLoading } = useStore((state) => state);
  const navigate = useNavigate();
  const isEmpty = !sessions || !sessions.length;

  const lastSessions = sessions
    .sort((a: SessionRead, b: SessionRead) => {
      if (a.created_date && b.created_date) {
        return (
          new Date(b.created_date).getTime() -
          new Date(a.created_date).getTime()
        );
      }
      return 0;
    })
    .filter((session: SessionRead) => session.images.length > 0)
    .slice(0, number);

  return (
    <ContainerTitle
      title="Les dernières sessions"
      goto="/sessions"
      isLoading={sessionsLoading}
      loading={Array(number)
        .fill("")
        .map((item, index) => (
          <ListItem key={index} divider={true} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <ListAltIcon color="secondary" sx={{ fontSize: "30px" }} />
              </ListItemIcon>
              <Skeleton animation="wave" width="100%">
                <ListItemText primary="." secondary="." />
              </Skeleton>
            </ListItemButton>
          </ListItem>
        ))}
      empty={isEmpty}
    >
      <List>
        {!sessionsLoading &&
          lastSessions?.length > 0 &&
          lastSessions?.map((session: SessionRead) => (
            <ListItem
              key={session.uuid}
              divider={true}
              disablePadding
              onClick={() => navigate(`/sessions/${session.uuid}`)}
            >
              <ListItemButton>
                <ListItemIcon>
                  <ListAltIcon color="secondary" sx={{ fontSize: "30px" }} />
                </ListItemIcon>
                <ListItemText
                  primary={session.uuid}
                  secondary={new Date(
                    session.created_date ?? ""
                  ).toLocaleDateString()}
                />
              </ListItemButton>
            </ListItem>
          ))}

        {!sessionsLoading && isEmpty && (
          <ListItem disablePadding>
            <ListItemText primary="Aucune session à afficher" />
          </ListItem>
        )}
      </List>
    </ContainerTitle>
  );
};

export default LastSessions;
