import { FC, ReactElement } from "react";
import { styled } from "@mui/material/styles";

interface LayoutUserProps {
  stats: ReactElement;
  map: ReactElement;
  pictures: ReactElement;
  sessions: ReactElement;
  period: ReactElement;
}

const LayoutUserContainer = styled("div")({
  display: "grid",
  gridTemplateColumns: "repeat(12, 1fr)",
  columnGap: "1rem",
  rowGap: "1rem",
});

const LayoutUserStats = styled("div")({
  gridColumn: "1/13",
});

const LayoutUserMap = styled("div")(({ theme }) => ({
  gridColumn: "1/8",
  [theme.breakpoints.down("md")]: {
    gridColumn: "1/13",
  },
}));

const LayoutUserSessions = styled("div")(({ theme }) => ({
  gridColumn: "8/13",
  [theme.breakpoints.down("md")]: {
    gridColumn: "1/13",
  },
}));

const LayoutUserPictures = styled("div")(({ theme }) => ({
  gridColumn: "1/9",
  [theme.breakpoints.down("md")]: {
    gridColumn: "1/13",
  },
}));

const LayoutUserPeriod = styled("div")(({ theme }) => ({
  gridColumn: "9/13",
  [theme.breakpoints.down("md")]: {
    gridColumn: "1/13",
  },
}));

const LayoutUser: FC<LayoutUserProps> = ({
  stats,
  map,
  sessions,
  pictures,
  period,
}) => {
  return (
    <LayoutUserContainer>
      <LayoutUserStats>{stats}</LayoutUserStats>
      <LayoutUserMap>{map}</LayoutUserMap>
      <LayoutUserSessions>{sessions}</LayoutUserSessions>
      <LayoutUserPictures>{pictures}</LayoutUserPictures>
      <LayoutUserPeriod>{period}</LayoutUserPeriod>
    </LayoutUserContainer>
  );
};

export default LayoutUser;
