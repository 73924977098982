import { GetState, SetState } from "zustand";
import { AlertColor } from "@mui/material";
import { GlobalState } from "./useStore";

export interface alertItem {
  message: string;
  severity: AlertColor;
  persist?: boolean;
  autoHideDuration?: number;
}

export interface AppState {
  snackbar: {
    open: boolean;
    alerts: alertItem[];
  };
  snackbarToggle(open?: boolean): void;
  snackbarSetAlerts(alerts?: alertItem[]): void;
}
const createApp = (set: SetState<GlobalState>, get: GetState<GlobalState>) => ({
  snackbar: {
    open: true,
    alerts: [],
  },
  snackbarToggle: (open: boolean) => {
    const snackbar = get().snackbar;
    set({
      snackbar: {
        ...snackbar,
        open: open ?? !snackbar.open,
      },
    });
  },
  snackbarSetAlerts: (alerts: alertItem[] = []) => {
    const snackbar = get().snackbar;
    set({
      snackbar: {
        ...snackbar,
        alerts,
      },
    });
  },
});
export default createApp;
