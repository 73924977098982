import { FC, useState, useEffect, MutableRefObject } from "react";
import {
  Typography,
  Grid,
  Stack,
  Button,
  Skeleton,
  GlobalStyles,
} from "@mui/material/";
import { Polygon } from "react-leaflet";
import StatList from "../components/user/StatList";
import LayoutUser from "../components/layout/User";
import useStore from "../store/useStore";
import Thumbnail from "../components/images/Thumbnail";
import { useNavigate } from "react-router-dom";
import MapBase from "../components/map/Base";
import ContainerTitle from "../components/commons/ContainerTitle";
import { ImageRead, SessionRead } from "../client";
import LastSessions from "../components/session/LastSessions";
import { SiteReadWithSessionUUID } from "../client/models/SiteReadWithSessionUUID";
import { LatLngExpression } from "leaflet";
import CalendarMoon from "../components/calendar/CalendarMoon";

const styles = {
  buttonData: {
    "&:hover": {
      backgroundColor: "secondary.dark",
    },
    color: "text.primary",
    fontWeight: "bold",
  },
};

const StatsContainer: FC = () => {
  const { statsGlobal, statsGlobalSet } = useStore((state) => state);

  return (
    <Stack spacing={2} alignItems="center">
      <Stack direction={{ md: "row", xs: "column" }}>
        <Button
          disableElevation
          variant="outlined"
          color="secondary"
          sx={[
            { bgcolor: statsGlobal ? "secondary.main" : "secondary.light" },
            styles.buttonData,
          ]}
          onClick={() => statsGlobalSet(true)}
        >
          Toutes les données
        </Button>
        <Button
          disableElevation
          variant="outlined"
          color="secondary"
          sx={[
            { bgcolor: statsGlobal ? "secondary.light" : "secondary.main" },
            styles.buttonData,
          ]}
          onClick={() => statsGlobalSet(false)}
        >
          Mes données
        </Button>
      </Stack>
      <StatList globalData={statsGlobal} />
    </Stack>
  );
};

const MapLayoutContainer: FC = () => {
  const navigate = useNavigate();
  const { sites } = useStore((state) => state);
  const [mapRefs, setMapRefs] = useState<{
    map: L.Map;
    featureGroup: MutableRefObject<L.FeatureGroup>;
  }>();

  useEffect(() => {
    const featureGroup = mapRefs?.featureGroup?.current;

    if (featureGroup && featureGroup.getBounds().isValid()) {
      mapRefs?.map.fitBounds(featureGroup.getBounds());
    }
  }, [mapRefs, sites]);

  const handleOnInit = (
    map: L.Map,
    featureGroup: MutableRefObject<L.FeatureGroup>
  ) => {
    setMapRefs({
      map,
      featureGroup,
    });
  };

  return (
    <ContainerTitle height="100%" title="Carte des sites" goto="/sites">
      <MapBase onInit={handleOnInit}>
        {sites &&
          sites.records.map((record: SiteReadWithSessionUUID) => (
            <Polygon
              key={record.uuid}
              pathOptions={{
                color: "#4BAFE8",
              }}
              positions={record.geom.coordinates[0].map(
                (a: LatLngExpression[]) => a.slice().reverse()
              )}
              eventHandlers={{
                click: () => {
                  navigate(`/sites/${record.uuid}`);
                },
              }}
            />
          ))}
      </MapBase>
    </ContainerTitle>
  );
};

const PicturesContainer: FC = () => {
  const { sessions, sessionsLoading } = useStore((state) => state);
  const lastSession = sessions?.records.find(
    (session: SessionRead) => session.images.length >= 5
  );
  const lastImages = lastSession?.images.slice(0, 5);

  return (
    <ContainerTitle title="Dernières photos" goto="/gallery">
      <Grid container gap={1} justifyContent={"flex-start"}>
        {sessionsLoading &&
          Array(5)
            .fill("")
            .map((item, index) => (
              <Grid key={index} item>
                <Skeleton animation="wave" variant="rectangular" height="100%">
                  <Thumbnail image={undefined} />
                </Skeleton>
              </Grid>
            ))}
        {!sessionsLoading &&
          lastImages &&
          lastImages?.length > 0 &&
          lastImages.map((image: ImageRead) => (
            <Grid key={image.checksum} item>
              <Thumbnail image={image} />
            </Grid>
          ))}

        {!sessionsLoading && !lastImages?.length && (
          <Typography>Aucune photo à afficher</Typography>
        )}
      </Grid>
    </ContainerTitle>
  );
};

const calendarGlobalStyles = (
  <GlobalStyles
    styles={{
      ".react-calendar": {
        cursor: "pointer",
      },
    }}
  />
);

const PeriodContainer: FC = () => {
  const navigate = useNavigate();
  return (
    <ContainerTitle title="Prochaine bonne période">
      {calendarGlobalStyles}
      <CalendarMoon
        onClick={() => navigate("/calendar")}
        showNeighboringMonth={false}
        prevLabel={null}
        nextLabel={null}
        borderTiles={true}
      />
    </ContainerTitle>
  );
};

const UserPage: FC = () => {
  const { sitesGet, sessionsGet, statsGetAll, sessions, setSessionFilter } =
    useStore((state) => state);

  useEffect(() => {
    const fetchData = async () => {
      setSessionFilter();
      await statsGetAll();
      await sitesGet([]);
      await sessionsGet([]);
    };

    fetchData();
  }, [sessionsGet, sitesGet, setSessionFilter, statsGetAll]);

  return (
    <LayoutUser
      stats={<StatsContainer />}
      map={<MapLayoutContainer />}
      sessions={<LastSessions sessions={sessions?.records} number={5} />}
      pictures={<PicturesContainer />}
      period={<PeriodContainer />}
    />
  );
};

export default UserPage;
