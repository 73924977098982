import { Stack, Typography, IconButton, Button, Tooltip } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { FC, ReactNode } from "react";
import { useNavigate } from "react-router-dom";

interface ContainerTitleProps {
  title: string;
  actionButtonText?: string;
  goto?: string;
  isLoading?: boolean;
  loading?: ReactNode;
  empty?: boolean;
  children: ReactNode;
  onClose?(): void;
  height?: string;
}

const ContainerTitle: FC<ContainerTitleProps> = ({
  title,
  actionButtonText = "Voir tout",
  goto,
  isLoading = false,
  loading = () => <div>En cour de chargement...</div>,
  empty,
  onClose,
  height = "",
  children,
}) => {
  const navigate = useNavigate();

  return (
    <Stack
      spacing={1}
      sx={{
        padding: "1rem",
        height: { height },
      }}
    >
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ marginBottom: "1rem" }}
      >
        <Typography sx={{ flexGrow: 1 }} variant="h3">
          {title}
        </Typography>

        {goto && !empty && (
          <Button
            size="small"
            variant="outlined"
            onClick={() => navigate(goto)}
            disableElevation={true}
          >
            {actionButtonText}
          </Button>
        )}

        {onClose && (
          <Tooltip title="Revenir">
            <IconButton onClick={() => onClose()}>
              <ArrowBackIcon />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
      <>{isLoading ? loading : children}</>
    </Stack>
  );
};

export default ContainerTitle;
