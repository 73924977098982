import { FC } from "react";
import { Link, Stack, Typography, Box } from "@mui/material";

const styles = {
  footerContainer: {
    boxShadow: "0px -2px 1px -1px rgba(0, 0, 0, 0.2)",
    padding: 4,
    backgroundColor: "primary.dark",
    color: "primary.contrastText",
  },
  footer: {
    display: "flex",
    alignItems: "center",
    textAlign: { md: "left", xs: "center" },
    justifyContent: "space-evenly",
  },
  title: {
    fontWeight: 700,
    color: "primary.main",
  },
  paragraph: {
    width: { xs: "80%", md: "40%" },
    margin: 0,
    order: { xs: 3, md: 2 },
  },
};

const Footer: FC = () => {
  return (
    <Stack sx={styles.footerContainer}>
      <Stack
        sx={styles.footer}
        spacing={1}
        direction={{ md: "row", xs: "column" }}
      >
        <Box sx={{ mt: { xs: 1, md: 0 }, order: { xs: 4, md: 1 } }}>
          <img alt="noe-logo" src="/assets/logonoe.svg" height={100} />
        </Box>
        <Typography paragraph={true} align="justify" sx={styles.paragraph}>
          Lépinoc est un programme de sciences participatives de suivi des
          papillons de nuit imaginé et porté par l’association Noé. Noé est une
          association française d’intérêt général créée en 2001 qui agit en
          France et à l’international pour la sauvegarde de la biodiversité.
        </Typography>
        <Stack spacing={1} sx={{ order: { xs: 1, md: 3 } }}>
          {/* <Typography sx={styles.title}>Liens utiles</Typography> */}
          <Link target="_blank" href="https://noe.org/">
            Noé
          </Link>
          <Link target="_blank" href="https://noe.org/programme-lepinoc">
            Programme Lépinoc
          </Link>
          <Link target="_blank" href="https://noe.org/mentions-legales">
            Mentions légales
          </Link>
        </Stack>
        <Stack spacing={1} sx={{ order: { xs: 2, md: 4 } }}>
          <Link
            target="_blank"
            href="https://noe.org/programme-lepinoc#slideshow-titre"
          >
            Partenaires
          </Link>
          <Link target="_blank" href="mailto: lepinoc@noe.org">
            Contactez-nous
          </Link>
          <Link target="_blank" href="/">
            Téléchargez l'application mobile
          </Link>
        </Stack>
      </Stack>
      <Typography variant="body2" pt={3} align="center">
        Site web et application mobile développés par{" "}
        <Link target="_blank" href="https://www.natural-solutions.eu/">
          Natural Solutions
        </Link>{" "}
      </Typography>
    </Stack>
  );
};

export default Footer;
