import { Button, Stack, TextField } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { SitesService, SiteUpdate } from "../../client";
import useStore from "../../store/useStore";
import ContainerTitle from "../commons/ContainerTitle";
import CommonsMultiSelect from "../commons/MultiSelect";
interface SiteMetadataLayoutProps {
  onCancel?(): void;
}

const SiteEdition: FC<SiteMetadataLayoutProps> = ({ onCancel }) => {
  const { site, user, setSite, groupsOthers } = useStore((state) => state);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const { register, reset, handleSubmit } = useForm();
  const onSubmit = async (data: SiteUpdate) => {
    if (site) {
      try {
        const siteUpdated = await SitesService.updateSiteSitesSiteUuidPatch(
          site.uuid,
          {
            ...data,
            shared_ids: site.shared_ids,
          }
        );
        setSite(siteUpdated);
        setBtnDisabled(true);
      } catch (e) {
        setBtnDisabled(false);
      }
    }
  };

  useEffect(() => {
    reset();
  }, [site, reset]);

  if (!site) {
    return null;
  }

  const isOwner = site.owner_id === user?.user.sub;

  return (
    <ContainerTitle title={"Site: " + site?.name} onClose={onCancel}>
      {isOwner && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2}>
            <TextField
              {...register("name")}
              name="name"
              label="Nom du site"
              variant="filled"
              value={site?.name}
              fullWidth
              sx={{ bgcolor: "#E0E0E0" }}
              onChange={(e) => {
                setSite({
                  ...site,
                  name: e.target.value,
                });
                setBtnDisabled(false);
              }}
            />
            <Stack direction="row" justifyContent="flex-end">
              <Button
                variant="contained"
                sx={{
                  "&:hover": {
                    backgroundColor: "secondary.dark",
                  },
                  bgcolor: "secondary.main",
                  color: "secondary.contrastText",
                }}
                disableElevation
                type="submit"
                disabled={btnDisabled}
              >
                sauvegarder
              </Button>
            </Stack>
          </Stack>
        </form>
      )}
    </ContainerTitle>
  );
};

export default SiteEdition;
