import { GetState, SetState } from "zustand";
import { GroupsService, KeycloakGroup } from "../client";
import { GlobalState } from "./useStore";

export interface GroupsState {
  groups: KeycloakGroup[];
  groupsOthers(): KeycloakGroup[];
  groupGetMe(): KeycloakGroup | undefined;
  groupGetAll(): Promise<KeycloakGroup[]>;
}

const createGroups = (
  set: SetState<GlobalState>,
  get: GetState<GlobalState>
) => ({
  groups: [],
  groupsOthers: () => {
    const groups = get().groups;
    const user = get().user;

    return groups.filter((group) => group.id !== user?.groups[0]?.id);
  },
  groupGetMe: () => {
    return get().user?.groups[0];
  },
  groupGetAll: async () => {
    const groups = await GroupsService.allGroupsGroupsGet();

    set(() => ({ groups }));

    return groups;
  },
});

export default createGroups;
