import { Typography } from "@mui/material";
import { FC } from "react";
import { TextFieldProps } from "react-admin";

export const AdminJsonFieldValue: FC<TextFieldProps & { value: any }> = (
  props
) => {
  const { maxWidth, noWrap } = props;
  const maxW =
    typeof maxWidth === "undefined"
      ? 150
      : maxWidth === -1
      ? undefined
      : maxWidth;
  let value = "";
  try {
    value = JSON.stringify(props.value);
  } catch (error) {}
  return (
    <Typography
      maxWidth={maxW}
      noWrap={typeof noWrap != "undefined" ? noWrap : true}
    >
      {value}
    </Typography>
  );
};
