import { FC } from "react";
import { Controller, UseFormReturn } from "react-hook-form";

const HookFormHiddenField: FC<{
  formApi: UseFormReturn<any>;
  name: string;
  defaultValue?: any;
}> = ({ formApi, name, defaultValue }) => {
  return (
    <Controller
      name={name}
      control={formApi.control}
      defaultValue={defaultValue}
      render={() => <></>}
    />
  );
};
export default HookFormHiddenField;
