import { FC } from "react";
import { KeycloakGroup, SiteRead, UserGroup } from "../../client";
import { Alert } from "@mui/material";

export interface IsNotVisibleAlertProps {
  site: undefined | SiteRead;
  user: undefined | UserGroup;
}

const IsNotVisibleAlert: FC<IsNotVisibleAlertProps> = ({ site, user }) => {
  const groupIds: undefined | string[] = user?.groups.map(
    (group: KeycloakGroup) => group.id
  );
  const isOwner = site?.owner_id === user?.user.sub;
  const sharedIds = site?.shared_ids;
  const isVisible =
    isOwner ||
    (groupIds && site?.group_id && site?.group_id in groupIds) ||
    (groupIds &&
      sharedIds != undefined &&
      groupIds.every((id) => id in sharedIds));

  return !isVisible ? (
    <Alert severity="warning">
      Le nom du propriétaire ainsi que sa géométrie ont été floutées
    </Alert>
  ) : (
    <></>
  );
};

export default IsNotVisibleAlert;
