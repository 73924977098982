import { ChangeEvent, FC, useState, useEffect } from "react";
import useStore from "../../store/useStore";
import {
  IconButton,
  Paper,
  Skeleton,
  Stack,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Chip,
  Alert,
  AlertTitle,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { LoadingButton } from "@mui/lab";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import IconInfo from "@mui/icons-material/Info";
import { SessionRead, SessionsService } from "../../client";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import CommonAlertDialog from "../commons/AlertDialog";

export interface Pagination {
  page: number;
}

export interface SessionListProps {
  onDeleted?(session: SessionRead): void;
}

const SessionList: FC<SessionListProps> = ({ onDeleted = () => {} }) => {
  const navigate = useNavigate();
  const { sessions, sessionsLoading, sessionFilter, sessionsGetQuery, user } =
    useStore((state) => state);
  const [downloading, setDownloading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [dialogOpen, setDialogOpen] = useState(false);
  //const [completeSessions, setCompleteSessions] = useState<boolean>(false);

  /* useEffect(() => {
    const evaluateSessionsComplete = sessions?.records
      .filter((session) => session?.owner_id === user?.user?.sub)
      ?.every((session) => {
        return session?.form?.managementPractices;
      });
    setCompleteSessions(evaluateSessionsComplete);
  }, [sessions]); */

  const handleRowsPerPage = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const newFilters = {
      ...sessionFilter,
      currentPage: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    };
    const newSearchParams: URLSearchParams = new URLSearchParams({
      ...(newFilters as unknown as Record<string, string>),
    });

    setSearchParams(newSearchParams);
  };

  const handleDownload = async () => {
    setDownloading(true);
    const query = sessionsGetQuery([]);
    const { success } = await SessionsService.exportSessionsExportGet(query);

    if (success) {
      setDialogOpen(true);
    }

    setDownloading(false);
  };

  return (
    <Paper>
      {/* {!completeSessions && (
        <Alert severity="error">
          <AlertTitle>Informations manquantes</AlertTitle>
          Certaines de vos sessions ne sont pas complètes. N'oubliez pas de
          remplir le <strong>formulaire de pratique de gestion</strong>. Ces
          données sont primordiales pour le projet et le bon suivi des papillons
          de nuit.
        </Alert>
      )} */}
      <TableContainer sx={{ height: "calc(100vh - 460px)" }}>
        <Table size="small" stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell component="th"></TableCell>
              <Tooltip
                placement="top-start"
                title="identifiant unique d'une session"
              >
                <TableCell component="th">UUID de session</TableCell>
              </Tooltip>
              <TableCell component="th">Date de début</TableCell>
              <TableCell component="th">Site associé</TableCell>
              <TableCell component="th">Session complète</TableCell>
              <Tooltip
                placement="top-start"
                title="identifiant unique d'un dispositif"
              >
                <TableCell component="th">UUID de dispositif</TableCell>
              </Tooltip>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {sessionsLoading &&
              Array(sessionFilter.rowsPerPage)
                .fill("")
                .map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Skeleton animation="wave" />
                    </TableCell>
                    <TableCell>
                      <Skeleton animation="wave" />
                    </TableCell>
                    <TableCell>
                      <Skeleton animation="wave" />
                    </TableCell>
                    <TableCell>
                      <Skeleton animation="wave" />
                    </TableCell>
                    <TableCell>
                      <Skeleton animation="wave" />
                    </TableCell>
                    <TableCell>
                      <Skeleton animation="wave" />
                    </TableCell>
                    <TableCell>
                      <Skeleton animation="wave" />
                    </TableCell>
                  </TableRow>
                ))}
            {!sessionsLoading &&
              sessions.records.map((session: SessionRead) => {
                const isComplete = session?.images.length >= 17;

                const isOwner = session?.owner_id === user?.user?.sub;

                return (
                  session.images.length > 0 && (
                    <TableRow
                      key={session.uuid}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      hover={true}
                    >
                      <TableCell>
                        <Tooltip title="+ d'infos">
                          <IconButton
                            onClick={() =>
                              navigate(`/sessions/${session.uuid}`)
                            }
                          >
                            <IconInfo />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell>{session?.uuid}</TableCell>
                      <TableCell>
                        {new Date(
                          String(session?.sunset_date)
                        ).toLocaleString()}
                      </TableCell>
                      <TableCell>{session.site.name}</TableCell>
                      <TableCell align="center">
                        <Chip
                          icon={isComplete ? <CheckIcon /> : <CloseIcon />}
                          label={isComplete ? "Oui" : "Non"}
                          variant="outlined"
                          color={isComplete ? "success" : "error"}
                          disabled={!isOwner}
                          onClick={() =>
                            navigate(
                              `/sessions/${session.uuid}?form=management-practices`
                            )
                          }
                        />
                      </TableCell>
                      <TableCell>{session.device.uuid}</TableCell>
                      <TableCell>
                        {isOwner && (
                          <Tooltip title="supprimer la session">
                            <IconButton onClick={() => onDeleted(session)}>
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </TableCell>
                    </TableRow>
                  )
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack>
        <Stack
          direction={"row"}
          justifyContent="flex-end"
          alignItems="center"
          sx={{ p: 2 }}
        >
          <LoadingButton
            variant="contained"
            color="secondary"
            loading={downloading}
            onClick={handleDownload}
          >
            Telecharger
          </LoadingButton>
        </Stack>
        <Divider />
        <TablePagination
          component="div"
          labelRowsPerPage={"Sessions par page"}
          onPageChange={(
            event: React.MouseEvent<HTMLButtonElement> | null,
            page: number
          ) => {
            const newFilters = {
              ...sessionFilter,
              currentPage: page,
            };
            const newSearchParams: URLSearchParams = new URLSearchParams({
              ...(newFilters as unknown as Record<string, string>),
            });

            setSearchParams(newSearchParams);
          }}
          SelectProps={{
            inputProps: {
              "aria-label": "Sessions par page",
            },
            native: true,
          }}
          colSpan={6}
          count={sessions.metadata.total}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          rowsPerPage={sessionFilter.rowsPerPage}
          page={sessionFilter.currentPage}
          onRowsPerPageChange={handleRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Stack>
      <CommonAlertDialog
        title="Veuillez patienter"
        content={[
          "L'export est en cours, vous allez recevoir bientôt un email avec un lien vers celui-ci pour que vous puissiez le télécharger",
        ]}
        open={dialogOpen}
        agreeLabel="D'accord"
        onAgree={() => setDialogOpen(false)}
      />
    </Paper>
  );
};

export default SessionList;
