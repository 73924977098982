import {
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { FC } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { HookFormErrorMessage } from "./HookFormErrorMessage";
import { Clear } from "@mui/icons-material";

type HookFormTextFieldProps = {
  name: string;
  formApi: UseFormReturn;
  hasClearButton?: boolean;
  textFieldProps?: TextFieldProps;
};

export const HookFormTextField: FC<HookFormTextFieldProps> = (props) => {
  const control = props.formApi.control;
  const textFieldProps: TextFieldProps = props.textFieldProps || {};
  textFieldProps.type = textFieldProps.type || "text";
  textFieldProps.required = textFieldProps.required === true ? true : false;
  textFieldProps.variant = textFieldProps.variant || "outlined";
  textFieldProps.fullWidth = textFieldProps.fullWidth === false ? false : true;
  textFieldProps.inputProps = textFieldProps.inputProps || {};
  textFieldProps.InputLabelProps = textFieldProps.InputLabelProps || {};
  let hasClearButton = props.hasClearButton;
  if (textFieldProps.type === "number") {
    textFieldProps.inputProps.step = "any";
    if (hasClearButton !== false && !textFieldProps.disabled) {
      hasClearButton = true;
    }
  }

  return (
    <Stack direction="column" width="100%">
      <Controller
        control={control}
        name={props.name}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          if (value == null) {
            value = "";
          }
          const InputProps: any = textFieldProps.InputProps || {};
          if (hasClearButton) {
            InputProps.endAdornment = (
              <InputAdornment position="end">
                <IconButton onClick={() => onChange(null)}>
                  <Clear />
                </IconButton>
              </InputAdornment>
            );
          }
          return (
            <>
              <TextField
                value={value}
                name={props.name}
                {...textFieldProps}
                InputProps={InputProps}
                onChange={(e) => {
                  const value = e.target.value;
                  if (textFieldProps.type === "number") {
                    if (typeof value === "string" && !value) {
                      onChange(null);
                    }
                    onChange(Number(value));
                  } else {
                    onChange(value);
                  }
                }}
              />
              <HookFormErrorMessage error={error?.message} />
            </>
          );
        }}
      ></Controller>
    </Stack>
  );
};
