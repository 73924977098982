import { GetState, SetState } from "zustand";
import {
  SessionRead,
  SessionReadAll,
  SessionsService,
  SessionUpdate,
  SessionUsers,
} from "../client";
import { defaultRecords, GlobalState } from "./useStore";

export interface SessionFilter {
  uuid?: string;
  devices?: number[];
  sites?: number[];
  users?: string[];
  startDate?: Date;
  endDate?: Date;
  currentPage: number;
  rowsPerPage: number;
}

export interface SessionsState {
  sessions: SessionReadAll;
  session?: SessionRead;
  sessionSelected?: SessionRead;
  sessionFilter: SessionFilter;
  sessionsLoading: boolean;
  sessionUsers: SessionUsers;
  sessionGet(uuid: string): Promise<void>;
  sessionsGet(query?: string[]): Promise<SessionReadAll>;
  sessionsGetQuery(query: string[]): string[];
  sessionUsersGet(): Promise<void>;
  setSessionSelected(newSessionSelected?: SessionRead): void;
  setSession(newSession?: SessionRead): void;
  setSessionFilter(filter?: SessionFilter): void;
  sessionUpdate(uuid: string, updatedSession: SessionUpdate): void;
  sessionDelete(session: SessionRead): void;
}

export const defaultSessionFilter: SessionFilter = {
  uuid: undefined,
  devices: [],
  sites: [],
  users: [],
  startDate: undefined,
  endDate: undefined,
  currentPage: 0,
  rowsPerPage: 10,
};

const createSessions = (
  set: SetState<GlobalState>,
  get: GetState<GlobalState>
) => ({
  sessionsLoading: false,
  sessions: defaultRecords,
  session: undefined,
  sessionSelected: undefined,
  sessionFilter: defaultSessionFilter,
  sessionUsers: defaultRecords,
  sessionGet: async (uuid: string) => {
    const session = await SessionsService.getSessionSessionsSessionUuidGet(
      uuid
    );
    set(() => ({ session }));
    get().siteGet(session.site.uuid);
  },
  sessionsGetQuery: (query: string[] = []) => {
    const filters = get().sessionFilter;

    for (const filter of Object.keys(filters) as Array<keyof typeof filters>) {
      if (filters[filter] === "" || filters[filter] === undefined) {
        continue;
      }

      if (
        Array.isArray(filters[filter]) &&
        !(filters[filter] as number[]).length
      ) {
        continue;
      }

      switch (filter) {
        case "uuid":
          query.push(`uuid=${filters[filter]}`);
          break;
        case "devices":
          query.push(`device_id=in=(${filters[filter]})`);
          break;
        case "sites":
          query.push(`site_id=in=(${filters[filter]})`);
          break;
        case "startDate":
          query.push(`sunset_date=ge=${filters[filter]?.toISOString()}`);
          break;
        case "endDate":
          query.push(`sunset_date=le=${filters[filter]?.toISOString()}`);
          break;
        case "users":
          query.push(`owner_id=in=(${filters[filter]})`);
          break;
      }
    }

    query.push("sort(-sunset_date)");

    return query;
  },
  sessionsGet: async (query: string[] = []) => {
    set(() => ({
      sessionsLoading: true,
    }));

    try {
      const newQuery = get().sessionsGetQuery(query);
      const newSessions = await SessionsService.allSessionsSessionsGet(
        newQuery
      );

      set(() => ({
        sessions: newSessions as SessionReadAll,
      }));
    } catch (e) {
      set(() => ({
        sessions: defaultRecords,
      }));
    } finally {
      set(() => ({ sessionsLoading: false }));
    }

    return get().sessions;
  },
  sessionUsersGet: async () => {
    const sessionUsers =
      await SessionsService.getAllSessionsUsersSessionsUsersGet();

    set(() => ({ sessionUsers }));
  },
  setSession: (session?: SessionRead) => {
    set(() => ({ session }));
  },
  setSessionSelected: (sessionSelected?: SessionRead) => {
    set(() => ({ sessionSelected }));
  },
  setSessionFilter: (sessionFilter: SessionFilter = defaultSessionFilter) => {
    set({ sessionFilter });
  },
  sessionUpdate: async (uuid: string, updatedSession: SessionUpdate) => {
    const session = await SessionsService.updateSessionSessionsSessionUuidPatch(
      uuid,
      updatedSession
    );
    set(() => ({ session }));
  },
  sessionDelete: async (session: SessionRead) => {
    await SessionsService.deleteSessionSessionsSessionUuidDelete(session.uuid!);
    await get().sessionsGet();
  },
});

export default createSessions;
