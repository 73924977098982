import { FC } from "react";
import useStore from "../../store/useStore";
import {
  ArrayField,
  Datagrid,
  DateField,
  DateInput,
  FunctionField,
  List,
  TextField,
  TextInput,
  WithListContext
} from "react-admin";
import { AdminJsonFieldValue } from "./AdminJsonFieldValue";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";
import { SessionsService } from "../../client/services/SessionsService";

const filters: any[] = [
  <DateInput label="Date de création =" source="created_date" />,
  <DateInput label="Date de création >=" source="created_date@_gte" />,
  <DateInput label="Date de création <=" source="created_date@_lte" />,
  <TextInput label="Sunset (hh:mm) >=" source="view#sunset_time@_gte" />,
  <TextInput label="Sunset (hh:mm) <=" source="view#sunset_time@_lte" />,
  <TextInput
    label="Sunset (hh:mm) contains"
    source="view#sunset_time@_ilike"
  />,
  <TextInput label="Site" source="site#name@_ilike" />,
  <TextInput label="Owner" source="owner#username@_ilike" />,
  <TextInput label="Organisation" source="group#name@_ilike" />,
  <TextInput label="ID =" source="id" />,
  <TextInput label="ID >=" source="id@_gte" />,
  <TextInput label="ID <=" source="id@_lte" />,
  <TextInput label="UUID" source="uuid" />,
  <TextInput label="Device UUID" source="device#uuid" />,
  <TextInput label="Device owner" source="device#owner#username@_ilike" />,
  <TextInput label="Commentaire" source="comment" />,
];

interface IProps { }

export const AdminSessionList: FC<IProps> = (props) => {

  const exportAdmin = async () => {
    const { success } = await SessionsService.exportAdminSessionsExportAdminGet();
  }
  return (
    <>
      <Box width={200}>
        <Button variant="contained" onClick={() => exportAdmin()}>exporter</Button>
      </Box>
      <List filters={filters} exporter={false}>
        <Datagrid bulkActionButtons={false} rowClick="show">
          <TextField source="id" label="ID" />
          <DateField source="created_date" label="Créé le" />
          <TextField source="view.sunset_time" label="Sunset" />
          {/* <FunctionField
          label="Sunset"
          render={(record?: any) => {
            return new Date(record.sunset_date + "Z").toLocaleString();
          }}
        /> */}
          <TextField source="site.name" label="Site" />
          <FunctionField
            label="Geom"
            render={(record?: any) => (
              <AdminJsonFieldValue value={record?.geom?.coordinates} />
            )}
          />
          <ArrayField source="shareds" label="Invité" perPage={1}>
            <WithListContext
              render={(params) => {
                const nbMore = params.total - params.data.length;
                const perPage = 1;
                return (
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      params.setPerPage(
                        params.perPage != perPage ? perPage : 999999
                      );
                    }}
                  >
                    {params.data.map((shared) => shared.group.name).join(", ")}
                    {nbMore > 0 && ` +${nbMore}`}
                  </span>
                );
              }}
            />
          </ArrayField>
          <TextField source="owner.username" label="Owner" />
          <TextField source="group.name" label="Organisation" />
          <TextField source="device.uuid" label="Device UUID" />
          <TextField source="device.owner.username" label="Device owner" />
          <TextField source="uuid" label="UUID" />
          <TextField
            source="comment"
            label="Commentaire"
            noWrap={true}
            maxWidth={150}
            display="block"
          />
          <FunctionField
            label="Habitats"
            render={(record?: any) => (
              <AdminJsonFieldValue value={record?.form?.habitats} />
            )}
          />
          <FunctionField
            label="Pratiques de gestion"
            render={(record?: any) => (
              <AdminJsonFieldValue value={record?.form?.management_practices} />
            )}
          />
        </Datagrid>
      </List>
    </>

  );
};
