import { FC, useEffect, useState } from "react";
import {
  Button,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Alert,
} from "@mui/material";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import useStore from "../../store/useStore";
import { SitesService } from "../../client";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  managementPracticesForm,
  managementPracticesSchema,
} from "../../schemas/formManagementPractices";

const schema = yup.object({}).required();

interface fieldItem {
  name: string;
  label: string;
  options?: optionItem[];
  facultative?: boolean;
  enable: boolean;
}

interface optionItem {
  label: string;
  value: string;
}

const defaultOption: { [key: string]: optionItem } = {
  other: { label: "Autre", value: "x" },
  none: { label: "Aucun", value: "y" },
  unknown: { label: "Je ne sais pas", value: "z" },
};

export const FIELDS: fieldItem[] = [
  {
    name: "seeding",
    label: "Semis ou sur-semis ?",
    options: [
      { label: "Oui", value: "a" },
      { label: "Non", value: "b" },
      defaultOption.unknown,
    ],
    enable: true
  },
  {
    name: "cuttingType",
    label: "Type de fauche",
    options: [
      { label: "Fauche broyée", value: "a" },
      { label: "Fauche coupée", value: "b" },
      { label: "Pas de fauche", value: "c" },
      defaultOption.unknown,
    ],
    enable: true
  },
  {
    name: "cuttingPeriod",
    label: "Période des fauches",
    options: [
      { label: "Estivale (juin-août)", value: "a" },
      { label: "Précoce (janvier-mai)", value: "b" },
      { label: "Tardive (septembre-décembre)", value: "c" },
      defaultOption.unknown,
    ],
    enable: true
  },
  {
    name: "cuttingFrequency",
    label: "Fréquence des fauches",
    options: [
      { label: "< 1 an", value: "a" },
      { label: "> 2/an", value: "b" },
      { label: "1/an", value: "c" },
      { label: "2/an", value: "c" },
      defaultOption.unknown,
    ],
    enable: true
  },
  {
    name: "residueExportation",
    label: "Exportation des résidus",
    options: [
      { label: "Oui", value: "a" },
      { label: "Non", value: "b" },
      defaultOption.unknown,
    ],
    enable: true
  },
  {
    name: "pastureType",
    label: "type de pâturage",
    options: [
      { label: "Bovin", value: "a" },
      { label: "Caprin", value: "b" },
      { label: "Equidé", value: "c" },
      { label: "Ovin", value: "d" },
      { label: "Pas de pâturage", value: "e" },
      defaultOption.unknown,
      defaultOption.other,
    ],
    enable: true
  },
  {
    name: "pasturePressure",
    label: "Pression de pâturage (en nombre d'animaux)",
    enable: true
  },
  {
    name: "pastureSize",
    label: "Surface pâturée par an (en m2)",
    enable: true
  },
  {
    name: "pastureDuration",
    label: "Durée annuelle du pâturage (en nombre de mois)",
    enable: true
  },
  {
    name: "phytosanitary",
    label: "Traitement phytosanitaire",
    options: [
      defaultOption.none,
      { label: "Anti-limaces", value: "a" },
      { label: "Anti-mousses", value: "b" },
      { label: "Fongicide", value: "c" },
      { label: "Herbicide", value: "d" },
      defaultOption.unknown,
      defaultOption.other,
    ],
    enable: true
  },
  {
    name: "uncontrolledManagementPressure",
    label: "Pression de gestion non-contrôlée",
    options: [
      { label: "Cueillette", value: "a" },
      { label: "Incendies", value: "b" },
      { label: "Pâturage et terriers par les lapins et rongeurs", value: "c" },
      { label: "Travaux sur la parcelle", value: "d" },
      defaultOption.none,
      defaultOption.unknown,
      defaultOption.other,
    ],
    enable: true
  },
];

const ManagementPractices: FC = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<managementPracticesForm>({
    resolver: yupResolver(managementPracticesSchema),
  });
  const [fields, setFields] = useState<fieldItem[]>(FIELDS);
  const { setSite, site, user } = useStore((state) => state);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [isOwner, setIsOwner] = useState<boolean>(false);
  const { snackbarSetAlerts } = useStore((state) => state);

  useEffect(() => {
    setIsOwner(site?.owner_id === user?.user?.sub);
    !site?.management_practices &&
      snackbarSetAlerts([
        {
          message: " Le formulaire de pratique de gestion est incomplet",
          severity: "error",
          autoHideDuration: 10000,
        },
      ]);
    if (site?.management_practices) {
      if (["c", "z"].includes(site?.management_practices["cuttingType"])) {
        const newFields: fieldItem[] = fields.map<fieldItem>((f: fieldItem) => {
          if (["cuttingPeriod", "cuttingFrequency", "residueExportation"].includes(f.name))
            f.enable = false
          return f
        })
        setFields(newFields)
      }
      if (["e", "z"].includes(site?.management_practices["pastureType"])) {
        const newFields: fieldItem[] = fields.map<fieldItem>((f: fieldItem) => {
          if (["pasturePressure", "pastureSize", "pastureDuration"].includes(f.name))
            f.enable = false
          return f
        })
        setFields(newFields)
      }
    }

  }, [site?.uuid, site?.management_practices, user?.user?.sub]);

  const onSubmit: SubmitHandler<managementPracticesForm> = async (data) => {
    console.log(data)
    if (site?.uuid) {
      try {
        const siteUpdated = await SitesService.updateSiteSitesSiteUuidPatch(
          site.uuid,
          {
            management_practices: data,
          }
        );
        setSite(siteUpdated);
      } catch (e) {
        setBtnDisabled(false);
      } finally {
        setBtnDisabled(true);
        snackbarSetAlerts([
          {
            message:
              "Formulaire envoyé et complet, merci pour votre contribution",
            severity: "success",
          },
        ]);
      }
    }
  };

  const onSelectChange = (fieldName: string, value: any) => {
    console.log(fields)
    if (fieldName == "cuttingType") {
      const newFields: fieldItem[] = fields.map<fieldItem>((f: fieldItem) => {
        if (["cuttingPeriod", "cuttingFrequency", "residueExportation"].includes(f.name))
          f.enable = !["c", "z"].includes(value)
        return f
      })
      setFields(newFields)
    }
    if (fieldName == "pastureType") {
      const newFields: fieldItem[] = fields.map<fieldItem>((f: fieldItem) => {
        if (["pasturePressure", "pastureSize", "pastureDuration"].includes(f.name))
          f.enable = !["e", "z"].includes(value)
        return f
      })
      setFields(newFields)
    }
    if (fieldName != "cuttingType") {
      return;
    }
    fields.forEach((field) => {
      if (["cuttingPeriod", "cuttingFrequency"].includes(field.name)) {
        field.facultative = value === "c";
      }
    });
    setFields([...fields]);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2} mt={2}>
          {fields.filter((selectField: fieldItem) => selectField.enable).map((selectField: fieldItem) =>
            selectField?.options ? (
              <FormControl key={selectField.name} fullWidth>
                <InputLabel>{selectField.label}</InputLabel>
                <Controller
                  defaultValue={
                    site?.management_practices
                      ? site?.management_practices?.[
                      selectField.name as keyof managementPracticesForm
                      ]
                      : ""
                  }
                  name={selectField.name as keyof managementPracticesForm}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      required={!selectField.facultative}
                      error={
                        !!errors[
                        selectField.name as keyof managementPracticesForm
                        ]
                      }
                      disabled={!isOwner}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        onSelectChange(selectField.name, e.target.value);
                        setBtnDisabled(false);
                      }}
                      label={selectField.label}
                    >
                      {selectField?.options?.map((option, i) => {
                        return (
                          <MenuItem key={i} value={option.value}>
                            {option.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                />
              </FormControl>
            ) : (
              <Controller
                key={selectField.name}
                defaultValue={
                  site?.management_practices
                    ? site?.management_practices?.[
                    selectField.name as keyof managementPracticesForm
                    ]
                    : ""
                }
                name={selectField.name as keyof managementPracticesForm}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    required
                    disabled={!isOwner}
                    value={value}
                    type="number"
                    onChange={(e) => {
                      onChange(e);
                      setBtnDisabled(false);
                    }}
                    label={selectField.label}
                    inputProps={{ min: 0 }}
                  />
                )}
              />
            )
          )}

          {isOwner ? (
            <Button type="submit" variant="contained" disabled={btnDisabled}>
              Envoyer
            </Button>
          ) : (
            <Alert variant="filled" severity="info" sx={{ color: "#000000DE" }}>
              Vous n'avez pas les droits d'édition sur ce site
            </Alert>
          )}
        </Stack>
      </form>
    </>
  );
};

export default ManagementPractices;
