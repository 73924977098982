/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GlobalStatsModel } from '../models/GlobalStatsModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class StatisticsService {

    /**
     * Statistics
     * @returns GlobalStatsModel Successful Response
     * @throws ApiError
     */
    public static statisticsStatisticsGet(): CancelablePromise<GlobalStatsModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/statistics/',
        });
    }

}