import { FC, useEffect, useState } from "react";
import useStore from "../../store/useStore";
import ContainerTitle from "../commons/ContainerTitle";
import { Button, Stack } from "@mui/material";
import { HookFormTextField } from "../commons/HookFormTextField";
import { useForm } from "react-hook-form";
import { SessionsService } from "../../client";

interface IProps {}

export const SessionComment: FC<IProps> = (props) => {
  const formApi = useForm();
  const { setSession, session, user } = useStore((state) => state);
  const [isOwner, setIsOwner] = useState<boolean>(false);
  const { snackbarSetAlerts } = useStore((state) => state);

  useEffect(() => {
    if (session?.comment) {
      formApi.reset({
        comment: session?.comment,
      });
    }
  }, [session?.comment]);

  useEffect(() => {
    setIsOwner(session?.owner_id === user?.user?.sub);
  }, [session?.uuid, session?.comment, user?.user?.sub]);

  const onSubmit = async () => {
    if (session?.uuid) {
      try {
        const sessionUpdated =
          await SessionsService.updateSessionSessionsSessionUuidPatch(
            session.uuid,
            {
              comment: formApi.getValues().comment,
            }
          );
        setSession(sessionUpdated);
      } catch (e) {
      } finally {
        snackbarSetAlerts([
          {
            message: "Commentaire mis à jour, merci pour votre contribution",
            severity: "success",
          },
        ]);
      }
    }
  };

  if (!session || (!isOwner && !session?.comment)) {
    return null;
  }

  const __html = (session.comment as string)?.replace?.(/\n/g, "<br />");

  return (
    <ContainerTitle title="Commentaire">
      {!isOwner ? (
        <div
          dangerouslySetInnerHTML={{
            __html,
          }}
        ></div>
      ) : (
        <form onSubmit={formApi.handleSubmit(onSubmit)}>
          <HookFormTextField
            formApi={formApi}
            name="comment"
            textFieldProps={{ multiline: true }}
          />
          <Button type="submit" variant="contained" sx={{ mt: 2 }}>
            Envoyer
          </Button>
        </form>
      )}
    </ContainerTitle>
  );
};
