import create from "zustand";
import { devtools } from "zustand/middleware";
import createSites, { SitesState } from "./createSites";
import createDevices, { DeviceState } from "./createDevices";
import createImages, { ImagesState } from "./createImages";
import createSessions, { SessionsState } from "./createSessions";
import createMap, { MapState } from "./createMap";
import createGroups, { GroupsState } from "./createGroups";
import createUsers, { UsersState } from "./createUsers";
import createStats, { StatsState } from "./createStats";
import createApp, { AppState } from "./createApp";
import { MetaDataModel } from "../client";
export const defaultRecords = {
  records: [] as any[],
  metadata: {
    total: 0,
    limit: 20,
    previous: "",
    next: "",
  } as MetaDataModel,
};
export type GlobalState = MapState &
  SitesState &
  DeviceState &
  ImagesState &
  SessionsState &
  GroupsState &
  UsersState &
  AppState &
  StatsState;
export const useStore = create<GlobalState>(
  devtools((set, get) => ({
    ...createMap(set, get),
    ...createSites(set, get),
    ...createDevices(set, get),
    ...createImages(set, get),
    ...createSessions(set, get),
    ...createGroups(set, get),
    ...createApp(set, get),
    ...createUsers(set, get),
    ...createStats(set, get),
  }))
);
export default useStore;