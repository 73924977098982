import { Clear } from "@mui/icons-material";
import {
  FormControl,
  FormControlProps,
  IconButton,
  InputAdornment,
  InputLabel,
  Select,
  SelectProps,
  Stack,
} from "@mui/material";
import { FC } from "react";
import { Controller } from "react-hook-form";
import { HookFormErrorMessage } from "./HookFormErrorMessage";

type HookFormSelectProps = {
  name: string;
  formApi: any;
  hasClearButton?: boolean;
  formControlProps?: FormControlProps;
  selectProps?: SelectProps;
  renderChildren?: (value: any) => React.ReactNode[];
  children?: React.ReactNode;
  valueKey?: string;
  required?: boolean;
  onChange?: (value: any) => void;
};

export const HookFormSelect: FC<HookFormSelectProps> = (props) => {
  const control = props.formApi.control;
  const selectProps: SelectProps = props.selectProps || {};
  selectProps.type = selectProps.type || "text";
  selectProps.variant = selectProps.variant || "outlined";
  selectProps.fullWidth = selectProps.fullWidth === false ? false : true;
  selectProps.inputProps = selectProps.inputProps || {};
  const required = props.required === true ? true : false;
  const valueKey = props.valueKey || "id";

  let hasClearButton = props.hasClearButton;

  return (
    <Stack direction="column" width="100%">
      <Controller
        control={control}
        name={props.name}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          const InputProps: any = {};
          if (hasClearButton) {
            InputProps.endAdornment = (
              <InputAdornment position="end">
                <IconButton onClick={() => onChange(null)}>
                  <Clear />
                </IconButton>
              </InputAdornment>
            );
          }
          if (typeof value === "undefined" || value === null) {
            value = "";
          }
          if (Object.prototype.toString.call(value) === "[object Object]") {
            value = value[valueKey];
          }
          if (selectProps.multiple && !Array.isArray(value)) {
            value = [];
          }

          return (
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              required={required}
              {...props.formControlProps}
            >
              <InputLabel id="select-label">{selectProps.label}</InputLabel>
              <Select
                labelId="select-label"
                variant="outlined"
                value={value}
                onChange={(e) => {
                  onChange(e.target.value);
                  props.onChange?.(e.target.value);
                }}
                {...selectProps}
                name={props.name}
              >
                {props.renderChildren?.(value) || props.children}
              </Select>
              <HookFormErrorMessage error={error?.message} />
            </FormControl>
          );
        }}
      />
    </Stack>
  );
};
