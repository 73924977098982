/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SiteCreate } from '../models/SiteCreate';
import type { SiteReadWithSession } from '../models/SiteReadWithSession';
import type { SiteReadWithSessionAndImagesPaginated } from '../models/SiteReadWithSessionAndImagesPaginated';
import type { SiteReadWithSessionUUIDPaginated } from '../models/SiteReadWithSessionUUIDPaginated';
import type { SiteUpdate } from '../models/SiteUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SitesService {

    /**
     * All Sites
     * @param query
     * @returns SiteReadWithSessionUUIDPaginated Successful Response
     * @throws ApiError
     */
    public static allSitesSitesGet(
        query?: Array<string>,
    ): CancelablePromise<SiteReadWithSessionUUIDPaginated> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sites/',
            query: {
                'query': query,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create One Site
     * @param requestBody
     * @returns SiteReadWithSession Successful Response
     * @throws ApiError
     */
    public static createOneSiteSitesPost(
        requestBody: SiteCreate,
    ): CancelablePromise<SiteReadWithSession> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/sites/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * All Sites With Images
     * @param query
     * @returns SiteReadWithSessionAndImagesPaginated Successful Response
     * @throws ApiError
     */
    public static allSitesWithImagesSitesImagesGet(
        query?: Array<string>,
    ): CancelablePromise<SiteReadWithSessionAndImagesPaginated> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sites/images',
            query: {
                'query': query,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Site
     * @param siteUuid
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getSiteSitesSiteUuidGet(
        siteUuid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sites/{site_uuid}',
            path: {
                'site_uuid': siteUuid,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Site
     * @param siteUuid
     * @returns SiteReadWithSession Successful Response
     * @throws ApiError
     */
    public static deleteSiteSitesSiteUuidDelete(
        siteUuid: string,
    ): CancelablePromise<SiteReadWithSession> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/sites/{site_uuid}',
            path: {
                'site_uuid': siteUuid,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Site
     * @param siteUuid
     * @param requestBody
     * @returns SiteReadWithSession Successful Response
     * @throws ApiError
     */
    public static updateSiteSitesSiteUuidPatch(
        siteUuid: string,
        requestBody: SiteUpdate,
    ): CancelablePromise<SiteReadWithSession> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/sites/{site_uuid}',
            path: {
                'site_uuid': siteUuid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}