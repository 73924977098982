import { Routes, Route, useLocation } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";

import CalendarPage from "./pages/calendar";
import GalleryPage from "./pages/gallery";
import HomePage from "./pages/index";
import LayoutBase from "./components/layout/Base";
import SessionsPage from "./pages/sessions";
import SessionPage from "./pages/session";
import SitesPage from "./pages/sites";
import UserPage from "./pages/home";
import { useEffect } from "react";
import { OpenAPI } from "./client";
import CommonsScrollToTop from "./components/commons/ScrollTop";
import { RAdmin } from "./pages/admin";

const pages = [
  { path: "gallery", element: <GalleryPage /> },
  { path: "calendar", element: <CalendarPage /> },
  { path: "user", element: <UserPage /> },
];

function App() {
  const { initialized, keycloak } = useKeycloak();
  const location = useLocation();

  useEffect(() => {
    if (initialized && !keycloak.authenticated && location.pathname !== "/") {
      keycloak.login({
        redirectUri: `${window.location.origin}/user`,
      });
    }

    OpenAPI.TOKEN = async () => {
      return keycloak.token ?? "";
    };
  }, [
    initialized,
    keycloak,
    keycloak.authenticated,
    keycloak.token,
    location.pathname,
  ]);

  return (
    <CommonsScrollToTop>
      <Routes>
        <Route element={<LayoutBase container={false} />}>
          <Route index element={<HomePage />} />
        </Route>
        {initialized && keycloak.authenticated ? (
          <>
            <Route
              element={
                <LayoutBase
                  withoutFooter={true}
                  sx={{ display: "flex", height: "100%" }}
                  container={false}
                />
              }
            >
              <Route path="/sites" element={<SitesPage />} />
              <Route path="/sites/:siteId" element={<SitesPage />} />
              <Route path="/sessions/:sessionId" element={<SessionPage />} />
            </Route>
            <Route path="/admin/*" element={<RAdmin />} />
            <Route
              element={<LayoutBase withoutFooter={true} smallLogo={true} />}
            >
              <Route path="/gallery" element={<GalleryPage />} />
            </Route>
            <Route element={<LayoutBase withoutFooter={true} />}>
              <Route path="/sessions" element={<SessionsPage />} />
            </Route>
            <Route path="/" element={<LayoutBase />}>
              {pages.map((page) => (
                <Route
                  key={page.path}
                  path={page.path}
                  element={page.element}
                />
              ))}
            </Route>
          </>
        ) : null}
      </Routes>
    </CommonsScrollToTop>
  );
}

export default App;
