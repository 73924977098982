import { Stack } from "@mui/material";
import SessionImages from "./Images";
import SessionMetadata from "./Metadata";
import SessionInfoTabs from "./InfoTabs";
import { SessionComment } from "./Comment";

const SessionInfo = () => {
  return (
    <Stack spacing={2} sx={{ height: "calc(100vh - 64px)" }}>
      <SessionMetadata />
      <SessionImages />
      <SessionComment />
      <SessionInfoTabs />
    </Stack>
  );
};
export default SessionInfo;
