import { SetState, GetState } from "zustand";
import { UserGroup, UsersService } from "../client";
import { GlobalState } from "./useStore";

export interface UsersState {
  user: UserGroup | undefined;
  setCurrentUser(): void;
}

const createUsers = (
  set: SetState<GlobalState>,
  get: GetState<GlobalState>
) => ({
  user: undefined,
  setCurrentUser: async () => {
    try {
      const userJSON = localStorage.getItem("lepinoc-user");
      let localUser = userJSON ? JSON.parse(userJSON) : undefined;

      if (!localUser) {
        localUser = await UsersService.meUsersMeGet();
        localStorage.setItem("lepinoc-user", JSON.stringify(localUser));
      }

      set(() => ({ user: localUser }));
    } catch (e) {
      set(() => ({ user: undefined }));
    }
  },
});

export default createUsers;
