import { GetState, SetState } from "zustand";
import {
  PolygonModel,
  PointModel,
  SiteCreate,
  SitesService,
  SiteReadWithSession,
  SiteRead,
} from "../client";
import { SiteReadWithSessionUUIDPaginated } from "../client/models/SiteReadWithSessionUUIDPaginated";
import { defaultRecords, GlobalState } from "./useStore";
import { managementPracticesForm } from "../schemas/formManagementPractices";

export type SiteTemporal = {
  geom: PolygonModel;
  centroid: PointModel;
  area: number;
  layer: any;
  department?: string;
  shared_ids?: string[] | undefined;
  management_practices: managementPracticesForm
};

export interface SitesState {
  sites: SiteReadWithSessionUUIDPaginated;
  siteTemporal: SiteTemporal | undefined;
  site: SiteReadWithSession | undefined;
  sitesLoading: boolean;
  siteGet(uuid: string | undefined): Promise<void>;
  sitesGet(query?: string[]): Promise<void>;
  siteAdd(newSite: SiteCreate): Promise<SiteReadWithSession>;
  setSiteTemporal(siteTemporal: SiteTemporal | undefined): void;
  setSite(site: SiteReadWithSession | undefined): void;
  deleteSite(site: SiteRead): void;
}

const createSites = (
  set: SetState<GlobalState>,
  get: GetState<GlobalState>
) => ({
  sites: defaultRecords,
  siteTemporal: undefined,
  site: undefined,
  sitesLoading: false,
  siteGet: async (uuid: string | undefined) => {
    if (uuid) {
      const site = await SitesService.getSiteSitesSiteUuidGet(uuid);
      return get().setSite(site);
    }

    get().setSite(undefined);
  },
  sitesGet: async (query: string[] = []) => {
    set(() => ({ sitesLoading: true }));

    try {
      query.push("sort(-created_date)");
      // query.push("filter(mine)");
      const sites = await SitesService.allSitesSitesGet(query);

      set(() => ({ sites }));
    } catch (e) {
      set(() => ({
        sites: defaultRecords,
      }));
    } finally {
      set(() => ({ sitesLoading: false }));
    }
  },
  setSiteTemporal: (siteTemporal: SiteTemporal | undefined) => {
    set(() => ({ siteTemporal }));
  },
  siteAdd: async (newSite: SiteCreate) => {
    const site = await SitesService.createOneSiteSitesPost(newSite);

    set(() => ({
      siteTemporal: undefined,
    }));

    return site;
  },
  setSite: (site: SiteReadWithSession | undefined) => {
    set(() => ({ site }));
  },
  deleteSite: async (site: SiteRead) => {
    await SitesService.deleteSiteSitesSiteUuidDelete(site.uuid);
    set(() => ({
      site: undefined,
    }));
    await get().sitesGet();
  },
});

export default createSites;
