import { FC, useCallback } from "react";
import {
  Container,
  Stack,
  Typography,
  Button,
  Grid,
  Box,
  Avatar,
} from "@mui/material/";
import { useKeycloak } from "@react-keycloak/web";
import HomeHero from "../components/home/Hero";

const styles = {
  paragraph: {
    width: { xs: "100%", md: "40%" },
  },
  buttonAction: {
    px: 5,
    py: 1,
    fontSize: { md: "1.5rem" },
  },
  stepImage: {
    backgroundSize: "contain",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    height: { md: 100, xs: 80 },
    width: { md: 100, xs: 80 },
  },
};

const steps: StepProps[] = [
  {
    image: "/assets/landing/step1.svg",
    description: "Je choisis un espace vert ou naturel",
  },
  {
    image: "/assets/landing/step2.svg",
    description:
      "Une fois par mois lunaire, au minimum de mai à août, je place mon dispositif de suivi Lépinoc",
  },
  {
    image: "/assets/landing/step3.svg",
    description:
      "Je lance l’application mobile Lépinoc sur le smartphone du dispositif",
  },
  {
    image: "/assets/landing/step4.svg",
    description: "Le lendemain, je récupère le smartphone",
  },
  {
    image: "/assets/landing/step5.svg",
    description: " Je me connecte à une borne wifi pour téléverser mes données",
  },
  {
    image: "/assets/landing/step6.svg",
    description: "Je visualise mes photographies sur la plateforme web",
  },
];

interface StepProps {
  image?: string;
  description?: string;
  number?: number;
}

const Step: FC<StepProps> = ({ description, number, image }) => {
  return (
    <Stack alignItems={"center"} spacing={4}>
      <Box sx={[styles.stepImage, { backgroundImage: `url(${image})` }]} />
      <Stack
        direction={{ xs: "column", md: "row" }}
        spacing={1}
        alignItems="center"
      >
        <Avatar sx={{ width: 30, height: 30, bgcolor: "secondary.main" }}>
          {number}
        </Avatar>
        <Typography variant="body1" padding={2}>
          {description}
        </Typography>
      </Stack>
    </Stack>
  );
};

const HomePage: FC = () => {
  const { keycloak } = useKeycloak();
  const handleLogin = useCallback(() => {
    keycloak?.login({
      redirectUri: `${window.location.origin}/user`,
    });
  }, [keycloak]);

  return (
    <>
      <HomeHero />
      <Stack spacing={5} mb={5}>
        <Container>
          <Grid container columnSpacing={10} rowSpacing={5} sx={{ mt: 3 }}>
            {steps.map((step, i) => (
              <Grid key={i + 1} item xs={12} sm={6} md={4}>
                <Step
                  description={step.description}
                  number={i + 1}
                  image={step.image}
                />
              </Grid>
            ))}
          </Grid>
        </Container>
        <Stack direction="row" justifyContent="center" spacing={2}>
          {!keycloak.authenticated ? (
            <Stack spacing={2} direction={{ xs: "column", md: "row" }}>
              <Button
                size="large"
                disableElevation
                variant="contained"
                target="_blank"
                sx={[{ bgcolor: "primary.dark" }, styles.buttonAction]}
                href="https://noe.org/programme-lepinoc"
              >
                Participer
              </Button>
              <Button
                disableElevation
                variant="contained"
                color="primary"
                sx={styles.buttonAction}
                onClick={handleLogin}
              >
                Se connecter
              </Button>
            </Stack>
          ) : (
            <>
              <Button
                disableElevation
                sx={styles.buttonAction}
                variant="contained"
                color="primary"
                onClick={async () => {
                  localStorage.clear();
                  await keycloak.logout({
                    redirectUri: `${window.location.origin}/`,
                  });
                }}
              >
                Se déconnecter
              </Button>
            </>
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default HomePage;
