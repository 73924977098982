import { Typography } from "@mui/material";
import { FC } from "react";

type IProps = {
  error: any;
};

export const HookFormErrorMessage: FC<IProps> = ({ error }) => {
  if (!error) return null;
  return <Typography color={"red"}>{error}</Typography>;
};
