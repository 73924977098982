import { FC } from "react";
import { Stack } from "@mui/material";
import Stats from "./Stats";
import useStore from "../../store/useStore";
import PlaceIcon from "@mui/icons-material/Place";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import ListAltIcon from "@mui/icons-material/ListAlt";

export interface StatListProps {
  globalData?: boolean;
}

const StatList: FC<StatListProps> = ({ globalData }) => {
  const { statsGlobal, stats } = useStore((state) => state);

  const data = statsGlobal ? stats?.all : stats?.user;

  return (
    <Stack
      direction="row"
      justifyContent="space-around"
      alignItems="center"
      sx={{ width: "100%" }}
    >
      <Stats
        number={data?.sites}
        name="sites"
        icon={<PlaceIcon sx={{ fontSize: "40px", color: "primary.main" }} />}
      />
      <Stats
        number={data?.sessions}
        name="sessions"
        icon={
          <ListAltIcon sx={{ fontSize: "40px", color: "secondary.main" }} />
        }
      />
      <Stats
        number={data?.images}
        name="photos"
        icon={
          <PhotoCameraIcon sx={{ fontSize: "40px", color: "primary.dark" }} />
        }
      />
    </Stack>
  );
};
export default StatList;
