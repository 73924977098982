import { FC, useCallback, useEffect, useState } from "react";
import {
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { ImageFilter } from "../../store/createImages";
import { SessionRead, SiteReadWithSessionUUID } from "../../client";
import useStore, { defaultRecords } from "../../store/useStore";
import ContainerTitle from "../commons/ContainerTitle";

export interface ImageFiltersProps {
  onChange?(filterType: keyof ImageFilter, values: any): void;
}

const ImagesFilters: FC<ImageFiltersProps> = ({ onChange = () => {} }) => {
  const {
    imageFilter,
    sessions,
    sites,
    groups,
    groupGetAll,
    sitesGet,
    sessionsGet,
    setSessionFilter,
    setImagesFilter,
  } = useStore((state) => state);
  const [values, setValues] = useState({ ...imageFilter });
  const [options, setOptions] = useState({
    groups: [] as any[],
    sites: defaultRecords,
    sessions: defaultRecords,
  });

  const fetchData = useCallback(async () => {
    const querySession = [];
    const querySites = [];

    if (values.group !== "") {
      const ruleGroup = `group_id=${values.group}`;
      querySites.push(ruleGroup);
      querySession.push(ruleGroup);
    }

    if (values.site !== "") {
      querySession.push(`site_id=${values.site}`);
    }

    setSessionFilter();
    await sitesGet(querySites);
    await sessionsGet(querySession);
  }, [sessionsGet, sitesGet, setSessionFilter, values.group, values.site]);

  const handleChange = async (
    filterType: keyof ImageFilter,
    event: SelectChangeEvent<string | number>
  ) => {
    const value = event.target.value;

    const newValues = {
      ...values,
      [filterType]: value,
    };

    if (filterType === "group") {
      newValues["site"] = "";
      newValues["session"] = "";
    }

    if (filterType === "site") {
      newValues["session"] = "";
    }

    setValues(newValues);
    setImagesFilter(newValues);
    onChange(filterType, newValues);
  };

  useEffect(() => {
    groupGetAll();
  }, [groupGetAll]);

  useEffect(() => {
    if (groups.length > 0) {
      fetchData();
    }
  }, [fetchData, groups]);

  useEffect(() => {
    setValues({ ...imageFilter });
  }, [imageFilter]);

  useEffect(() => {
    const newOptions = {
      groups,
      sites,
      sessions,
    };

    setOptions(newOptions);
  }, [sites, sessions, groups]);

  return (
    <ContainerTitle title="Filtres">
      <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
        <FormControl fullWidth>
          <InputLabel>Organisme</InputLabel>
          <Select
            label="Organisme"
            onChange={(e) => handleChange("group", e)}
            value={options.groups.length > 0 ? values.group : ""}
          >
            <MenuItem value={""}>Tous</MenuItem>
            {options.groups.map((group) => (
              <MenuItem key={group.id} value={group.id}>
                {group.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel>Site</InputLabel>
          <Select
            label="site"
            onChange={(e) => handleChange("site", e)}
            value={options.sites.records.length > 0 ? values.site : ""}
          >
            <MenuItem value={""}>Tous</MenuItem>
            {options.sites?.records?.length ? (
              options.sites.records.map((site: SiteReadWithSessionUUID) => (
                <MenuItem key={site.id} value={String(site.id)}>
                  {site.name}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="" disabled>
                "aucun site"
              </MenuItem>
            )}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel>Session</InputLabel>
          <Select
            label="session"
            onChange={(e) => handleChange("session", e)}
            value={options.sessions.records.length > 0 ? values.session : ""}
          >
            <MenuItem value={""}>Tous</MenuItem>
            {options.sessions?.records?.length ? (
              options.sessions?.records?.map((session: SessionRead) => (
                <MenuItem key={session.uuid} value={session.uuid}>
                  {new Date(String(session?.sunset_date)).toLocaleString()}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="" disabled>
                "aucune session"
              </MenuItem>
            )}
          </Select>
        </FormControl>
      </Stack>
    </ContainerTitle>
  );
};
export default ImagesFilters;
