import { FC } from "react";
import { Grid } from "@mui/material";
import { ImageRead } from "../../client";
import useStore from "../../store/useStore";
import ContainerTitle from "../commons/ContainerTitle";
import Thumbnail from "../images/Thumbnail";

const SessionImages: FC = () => {
  const session = useStore((state) => state.session);

  if (!session || !session.images) {
    return null;
  }

  return (
    <ContainerTitle title="Photos" goto={`/gallery?session=${session?.uuid}`}>
      <Grid container gap={1}>
        {session.images &&
          session.images.map((image: ImageRead) => (
            <Grid key={image.checksum} item>
              <Thumbnail image={image} />
            </Grid>
          ))}
      </Grid>
    </ContainerTitle>
  );
};

export default SessionImages;
