import { GetState, SetState } from "zustand";
import { ImageRead, ImageReadAll, SessionsService } from "../client";
import { defaultRecords, GlobalState } from "./useStore";

export interface ImageFilter {
  group: string;
  site: string;
  session: string;
}

export interface ImagesState {
  imagesExportingProgress: number;
  imagesExporting: boolean;
  imageFilter: ImageFilter;
  images: ImageReadAll;
  imagesLoading: boolean;
  imageSelected: ImageRead | undefined;
  infiniteScroll: boolean;
  imageHasMore: boolean;
  imagesGetAll(query?: string[]): Promise<void>;
  setImageSelected(newImageSelected: ImageRead | undefined): void;
  setImagesFilter(filter: ImageFilter): void;
  setInfiniteScroll(value: boolean): void;
}

export const defaultImageFilter: ImageFilter = {
  group: "",
  site: "",
  session: "",
};

const createPictures = (
  set: SetState<GlobalState>,
  get: GetState<GlobalState>
) => ({
  imagesExportingProgress: 0,
  imagesExporting: false,
  imageFilter: defaultImageFilter,
  images: defaultRecords,
  imagesLoading: false,
  imageSelected: undefined,
  infiniteScroll: false,
  imageHasMore: true,
  setInfiniteScroll: (value: boolean) => {
    set(() => ({
      infiniteScroll: value,
    }));
  },
  setImageSelected: (newImageSelected: ImageRead | undefined) => {
    set(() => ({
      imageSelected: newImageSelected,
    }));
  },
  imagesGetAll: async (query: string[]) => {
    set(() => ({ imagesLoading: true }));
    const infiniteScroll = get().infiniteScroll;

    if (!infiniteScroll) {
      set(() => ({ images: defaultRecords }));
    }

    const filter = get().imageFilter;

    try {
      const newImages = await SessionsService.getSessionImagesSessionsImagesGet(
        query,
        filter.session !== "" ? filter.session : undefined,
        filter.site !== "" ? Number(filter.site) : undefined,
      );

      set((state) => ({
        images: infiniteScroll
          ? {
            records: [...state.images.records, ...newImages.records],
            metadata: {
              total: state.images.metadata.total,
              limit: 20,
              previous: newImages.metadata.previous,
              next: newImages.metadata.next,
            },
          }
          : newImages,
      }));
    } catch (e) {
      set(() => ({
        images: defaultRecords,
      }));
    } finally {
      set(() => ({ imagesLoading: false }));
    }

    const images = get().images;

    if (images.records.length) {
      const imageSelected = images.records[0];
      get().setImageSelected(imageSelected);
      get().sessionGet(imageSelected?.session?.uuid!);
    }
    set(() => ({
      imageHasMore: !(images.records.length === images.metadata.total),
    }));
  },
  setImagesFilter: (imageFilter: ImageFilter) => {
    set(() => ({
      images: defaultRecords,
      imageSelected: undefined,
      imageFilter,
    }));
  },
});

export default createPictures;
