import { FC, useState, MouseEvent } from "react";
import {
  Box,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  SelectChangeEvent,
  Chip,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

export interface CommonsMultiSelectProps {
  label: string;
  options: any[];
  optionLabel: string;
  optionValue: string;
  value?: (string | number)[];
  onChange?(values: (string | number)[]): void;
}

const CommonsMultiSelect: FC<CommonsMultiSelectProps> = ({
  label,
  options,
  optionLabel,
  optionValue,
  value,
  onChange,
}) => {
  const [open, setOpen] = useState(false);

  const findById = (value: any) => {
    const index = options.findIndex((g) => g[optionValue] === value);

    return options[index] ?? [];
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleChange = (event: SelectChangeEvent<typeof value>) => {
    const {
      target: { value: newValue },
    } = event;
    let changed;

    if (Array.isArray(newValue)) {
      changed = newValue.map((v) => findById(v)[optionValue]);
    }

    if (changed) {
      onChange && onChange(changed);
    }

    handleClose();
  };

  const handleDelete = (toDelete: any) => {
    if (value) {
      const newValue = value.filter((v: number | string) => v !== toDelete);
      onChange && onChange(newValue);
    }
  };

  return (
    <FormControl fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        open={open}
        multiple
        onChange={handleChange}
        value={value ?? []}
        onClose={handleClose}
        onOpen={handleOpen}
        label={label}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((select) => (
              <Chip
                clickable
                deleteIcon={
                  <CancelIcon
                    onMouseDown={(event: MouseEvent<HTMLOrSVGElement>) =>
                      event.stopPropagation()
                    }
                  />
                }
                key={select}
                label={findById(select)[optionLabel]}
                onDelete={() => handleDelete(select)}
              />
            ))}
          </Box>
        )}
      >
        {options.map((option, index) => (
          <MenuItem key={index} value={option[optionValue]}>
            {option[optionLabel]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CommonsMultiSelect;
