import { FC } from "react";
import { Datagrid, List, TextField, TextInput } from "react-admin";

const filters = [
  <TextInput label="Owner" source="owner#username@_ilike" />,
  <TextInput label="Organisation" source="group#name@_ilike" />,
  <TextInput label="ID =" source="id" />,
  <TextInput label="ID >=" source="id@_gte" />,
  <TextInput label="ID <=" source="id@_lte" />,
  <TextInput label="UUID" source="uuid" />,
];

interface IProps {}

export const AdminDeviceList: FC<IProps> = (props) => {
  return (
    <List filters={filters} exporter={false}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" label="ID" />
        <TextField source="owner.username" label="Owner" />
        <TextField source="group.name" label="Organisation" />
        <TextField source="uuid" label="UUID" />
      </Datagrid>
    </List>
  );
};
