import * as yup from 'yup';

export interface managementPracticesForm {
  seeding: string;
  cuttingType: string;
  cuttingPeriod?: string;
  cuttingFrequency?: string;
  residueExportation?: string;
  pastureType: string;
  pasturePressure?: number;
  pastureSize?: number;
  pastureDuration?: number;
  phytosanitary: string;
  uncontrolledManagementPressure: string;
}


export const managementPracticesSchema = yup.object().shape({
  seeding: yup.string().required(),
  cuttingType: yup.string().required(),
  cuttingPeriod: yup
    .string()
    .when("cuttingType", (cuttingType: string, schema: any) => {
      if (["c", "z"].includes(cuttingType)) {
        return schema.optional();
      }
      return schema.required();
    }),
  cuttingFrequency: yup
    .string()
    .when("cuttingType", (cuttingType: string, schema: any) => {
      if (["c", "z"].includes(cuttingType)) {
        return schema.optional();
      }
      return schema.required();
    }),
  residueExportation: yup.string()
    .when("cuttingType", (cuttingType: string, schema: any) => {
      if (["c", "z"].includes(cuttingType)) {
        return schema.optional();
      }
      return schema.required();
    }),
  pastureType: yup.string().required(),
  pasturePressure: yup
    .number()
    .when("pastureType", (pastureType: string, schema: any) => {
      if (["e", "z"].includes(pastureType)) {
        return schema.optional();
      }
      return schema.required();
    }),
  pastureSize: yup.number()
    .when("pastureType", (pastureType: string, schema: any) => {
      if (["e", "z"].includes(pastureType)) {
        return schema.optional();
      }
      return schema.required();
    }),
  pastureDuration: yup.number()
    .when("pastureType", (pastureType: string, schema: any) => {
      if (["e", "z"].includes(pastureType)) {
        return schema.optional();
      }
      return schema.required();
    }),
  phytosanitary: yup.string().required(),
  uncontrolledManagementPressure: yup.string().required(),
});