import { FC } from "react";
import { Box, Typography, Stack } from "@mui/material";
import useStore from "../../store/useStore";

const styles = {
  imageViewer: {
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    height: "500px",
    minWidth: "300px",
    maxWidth: "500px",
    backgroundColor: "primary.light",
    display: "flex",
    alignItems: "end",
    borderRadius: "5px 5px 0px 0px",
    margin: "0 auto",
    overflow: "hidden",
  },
  viewerCaption: {
    minWidth: "300px",
    maxWidth: "500px",
    borderRadius: "0 0 5px 5px",
    flex: 1,
    p: 2,
    textAlign: "center",
    background: "rgba(0,0,0,0.7)",
  },
  butterflyContainer: {
    opacity: "0.05",
    height: "150px",
  },
};

const ImageViewer: FC = () => {
  const { imageSelected } = useStore((state) => state);
  return (
    <>
      <Box
        sx={[
          styles.imageViewer,
          { backgroundImage: `url(${imageSelected?.url})` },
        ]}
      >
        {!imageSelected && (
          <Stack sx={{ flex: 1, height: "100%" }} justifyContent="space-around">
            <img
              style={styles.butterflyContainer}
              src="/assets/svgButterflies/Vector.svg"
              alt="butterfly"
            />
            <img
              style={styles.butterflyContainer}
              src="/assets/svgButterflies/Vector-1.svg"
              alt="butterfly"
            />
          </Stack>
        )}
      </Box>
      <Stack direction={"row"} justifyContent={"center"}>
        {imageSelected && (
          <Typography sx={styles.viewerCaption} color="primary.contrastText">
            {imageSelected?.created_date
              ? new Date(imageSelected?.created_date).toLocaleString()
              : imageSelected?.filename}
          </Typography>
        )}
      </Stack>
    </>
  );
};
export default ImageViewer;
