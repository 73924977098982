export const translations = {
  fr: {
    translation: {
      satisfaction: {
        equipment: "Êtes-vous satisfaits du dispositif ?",
        mobile_app: "Êtes-vous satisfaits de l'application mobile ?",
        web_app: "Êtes-vous satisfaits de la plateforme web ?",
        protocol: "Êtes-vous satisfaits de l'animation du protocole ?",
        session: "Avez-vous apprécié réaliser cette session ?",
        comment: "Commentarie",
      },
      habitats: {
        A1: "Buissons ou jeune forêt (< 5m de hauteur)",
        A2: {
          a: "Feuillus exclusifs",
          b: "Mixte dom. Feuillus (< 10% conifères)",
          c: "Mixte dom. Conifères (< 10 % feuillus)",
          d: "Conifères exclusifs",
        },
        B1: "Pelouses, marais et landes",
        B2: {
          a: "Pelouse calcaire sèche",
          b: "Lande herbacée",
          c: "Lande à bruyères",
          d: "Autres pelouses sèches",
          e: "Pelouse d'altitude",
          f: "Pelouse humide naturelle",
          g: "Pelouse inondée/marais pâturé",
          h: "Tourbière",
          i: "Roselière",
          j: "Marais salant (halophile)",
          k: "Autres zones humides ouvertes",
        },
        C1: "Milieux agricoles",
        C2: {
          a: "Prairie cultivée",
          b: "Prairie non cultivée",
          c: "Prairie/cultures mixtes",
          d: "Grandes cultures",
          e: "Vergers/vignes/cultures maraîchères",
          f: "Autres types de cultures",
        },
        C4: {
          a: "Non exploité (jachère)",
          d: "Céréales",
          e: "Arbres fruitiers",
          f: "Autres cultures attractives pour les pollinisateurs",
          g: "Autres cultures non attractives pour les pollinisateurs",
        },
        D1: "Milieux bâtis ou urbanisés",
        D2: {
          a: "Zone urbaine résidentielle",
          b: "Zone urbaine industrielle",
          c: "Zone suburbaine résidentielle",
          d: "Zone suburbaine industrielle",
          e: "Zone rurale",
        },
        D3: {
          "1": "Zones bâties prédominantes",
          "2": "Jardin",
          "3": "Parc public, zone de loisirs",
          "4": "Bord d'une route (< 50m)",
          "5": "Bord d'un chemin de fer (< 50m)",
          "6": "Décharge d'ordures",
        },
        D4: {
          a: "Avec peu d'espaces verts (< 25 %)",
          b: "avec des espaces verts surtout composés de pelouses",
          c: "Avec des espaces verts buissonants (parterres, friches)",
          d: "Avec des espaces verts fortement arborés",
          e: "Avec un (des) potager(s)",
        },
        E1: "Rochers terrestres ou côtiers",
        E2: {
          a: "Falaise",
          b: "Eboulis, pente roheuse",
          c: "Carrière",
          d: "Autres sols rocheux",
        },
        E4: {
          a: "A dominante calcaire avec végétation herbacée rase",
          b: "A dominante calcaire avec végétation herbacée haute",
          c: "A dominante calcaire avec végétation buissonnante",
          d: "A dominante non calcaire avec végétation herbacée rase",
          e: "A dominante non calcaire avec végétation herbacée haute",
          f: "A dominante non calcaire avec végétation buissonnante",
        },
      },
    },
  },
};
