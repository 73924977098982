import { Grid, Typography } from "@mui/material";
import { FC } from "react";
import { SessionRead } from "../../client";
import useStore from "../../store/useStore";
import ContainerTitle from "../commons/ContainerTitle";
import Thumbnail from "../images/Thumbnail";

const SiteImages: FC = () => {
  const sessions = useStore((state) => state.site?.sessions);
  const { site } = useStore((state) => state);

  let isEmpty = false;
  if (!sessions || !sessions.length) {
    isEmpty = true;
  }

  const lastSessions = sessions
    ?.sort((a, b) => {
      if (a.created_date && b.created_date) {
        return (
          new Date(b.created_date).getTime() -
          new Date(a.created_date).getTime()
        );
      }

      return 0;
    })
    .slice(0, 5);

  return (
    <ContainerTitle
      title="Photos"
      goto={`/gallery?site=${site?.id}`}
      empty={isEmpty}
    >
      {isEmpty && <Typography>Aucune photo à afficher pour ce site</Typography>}
      <Grid container gap={2}>
        {lastSessions &&
          lastSessions.map((session: SessionRead) => (
            <Grid key={session.uuid} item>
              <Thumbnail
                key={session.uuid}
                image={session.images[0]}
                caption={true}
              />
            </Grid>
          ))}
      </Grid>
    </ContainerTitle>
  );
};

export default SiteImages;
