import { FC, useState } from "react";
import {
  ArrayField,
  DateField,
  FunctionField,
  Show,
  SimpleShowLayout,
  TextField,
  WithListContext,
} from "react-admin";
import { AdminJsonFieldValue } from "./AdminJsonFieldValue";
import { Box, Button, Stack } from "@mui/material";
import { LayersControl, MapContainer, Marker, TileLayer } from "react-leaflet";

export const AdminSessionShow: FC = () => {
  const [curPhoto, setCurPhoto] = useState<any>();
  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="id" label="ID" />
        <TextField source="uuid" label="UUID" />
        <TextField source="site.name" label="Site" />
        <TextField source="owner.username" label="Owner" />
        <TextField source="group.name" label="Organisation" />
        <TextField source="device.uuid" label="Device UUID" />
        <TextField source="device.owner.username" label="Device owner" />
        <DateField source="created_date" label="Créé le" />
        <TextField source="view.sunset_time" label="Sunset" />
        <FunctionField
          label="Geom"
          render={(record?: any) => {
            const point = {
              lat: record?.geom?.coordinates?.[1],
              lng: record?.geom?.coordinates?.[0],
            };
            return (
              <>
                <AdminJsonFieldValue
                  value={record?.geom}
                  maxWidth={800}
                  noWrap={false}
                />
                <MapContainer
                  style={{
                    width: "600px",
                    height: "400px",
                  }}
                  center={point}
                  zoom={6}
                >
                  <Marker position={point} />
                  <LayersControl>
                    <LayersControl.BaseLayer name="OSM">
                      <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      />
                    </LayersControl.BaseLayer>
                    <LayersControl.BaseLayer checked name="IGN-F/Géoportail">
                      <TileLayer
                        attribution='&copy; <a href="https://www.ign.fr/">IGN</a> contributors'
                        url="https://wxs.ign.fr/an7nvfzojv5wa96dsga5nk8w/geoportail/wmts?layer=ORTHOIMAGERY.ORTHOPHOTOS&style=normal&tilematrixset=PM&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image%2Fjpeg&TileMatrix={z}&TileCol={x}&TileRow={y}"
                      />
                    </LayersControl.BaseLayer>
                  </LayersControl>
                </MapContainer>
              </>
            );
          }}
        />
        <ArrayField source="shareds" label="Invité" perPage={1}>
          <WithListContext
            render={(params) => {
              const nbMore = params.total - params.data.length;
              const perPage = 1;

              return (
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    params.setPerPage(
                      params.perPage != perPage ? perPage : 999999
                    );
                  }}
                >
                  {params.data.map((shared) => shared.group.name).join(", ")}
                  {nbMore > 0 && ` +${nbMore}`}
                </span>
              );
            }}
          />
        </ArrayField>
        <TextField
          source="comment"
          label="Commentaire"
          noWrap={true}
          maxWidth={150}
          display="block"
        />
        <ArrayField source="images" label="Photos">
          <WithListContext
            render={(params) => (
              <Stack direction="row">
                <Stack>
                  {params.data.map((photo, i) => (
                    <Button
                      key={photo.id}
                      sx={{ textAlign: "left" }}
                      onClick={() => {
                        setCurPhoto(photo);
                      }}
                    >
                      {!photo.created_date
                        ? ""
                        : new Date().toLocaleTimeString("fr-FR", {
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                    </Button>
                  ))}
                </Stack>
                <Box
                  sx={{
                    flex: 1,
                  }}
                >
                  {curPhoto && (
                    <img
                      src={curPhoto.exif.url}
                      style={{
                        width: "100%",
                      }}
                    />
                  )}
                </Box>
              </Stack>
            )}
          />
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  );
};
