import { FC } from "react";
import {
  Stack,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import useStore from "../../store/useStore";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  managementPracticesForm,
  managementPracticesSchema,
} from "../../schemas/formManagementPractices";

interface fieldItem {
  name: string;
  label: string;
  options?: optionItem[];
}

interface optionItem {
  label: string;
  value: string;
}

const defaultOption: { [key: string]: optionItem } = {
  other: { label: "Autre", value: "x" },
  none: { label: "Aucun", value: "y" },
  unknown: { label: "Je ne sais pas", value: "z" },
};

const fields: fieldItem[] = [
  {
    name: "seeding",
    label: "Semis ou sur-semis ?",
    options: [
      { label: "Oui", value: "a" },
      { label: "Non", value: "b" },
      defaultOption.unknown,
    ],
  },
  {
    name: "cuttingType",
    label: "Type de fauche",
    options: [
      { label: "Fauche broyée", value: "a" },
      { label: "Fauche coupée", value: "b" },
      { label: "Pas de fauche", value: "c" },
      defaultOption.unknown,
    ],
  },
  {
    name: "cuttingPeriod",
    label: "Période des fauches",
    options: [
      { label: "Estivale (juin-août)", value: "a" },
      { label: "Précoce (janvier-mai)", value: "b" },
      { label: "Tardive (septembre-décembre)", value: "c" },
      defaultOption.unknown,
    ],
  },
  {
    name: "cuttingFrequency",
    label: "Fréquence des fauches",
    options: [
      { label: "< 1 an", value: "a" },
      { label: "> 2/an", value: "b" },
      { label: "1/an", value: "c" },
      { label: "2/an", value: "c" },
      defaultOption.unknown,
    ],
  },
  {
    name: "residueExportation",
    label: "Exportation des résidus",
    options: [
      { label: "Oui", value: "a" },
      { label: "Non", value: "b" },
      defaultOption.unknown,
    ],
  },
  {
    name: "pastureType",
    label: "type de pâturage",
    options: [
      { label: "Bovin", value: "a" },
      { label: "Caprin", value: "b" },
      { label: "Equidé", value: "c" },
      { label: "Ovin", value: "d" },
      { label: "Pas de pâturage", value: "e" },
      defaultOption.unknown,
      defaultOption.other,
    ],
  },
  {
    name: "pasturePressure",
    label: "Pression de pâturage (en nombre d'animaux)",
  },
  {
    name: "pastureSize",
    label: "Surface pâturée par an (en m2)",
  },
  {
    name: "pastureDuration",
    label: "Durée annuelle du pâturage (en nombre de mois)",
  },
  {
    name: "phytosanitary",
    label: "Traitement phytosanitaire",
    options: [
      defaultOption.none,
      { label: "Anti-limaces", value: "a" },
      { label: "Anti-mousses", value: "b" },
      { label: "Fongicide", value: "c" },
      { label: "Herbicide", value: "d" },
      defaultOption.unknown,
      defaultOption.other,
    ],
  },
  {
    name: "uncontrolledManagementPressure",
    label: "Pression de gestion non-contrôlée",
    options: [
      { label: "Cueillette", value: "a" },
      { label: "Incendies", value: "b" },
      { label: "Pâturage et terriers par les lapins et rongeurs", value: "c" },
      { label: "Travaux sur la parcelle", value: "d" },
      defaultOption.none,
      defaultOption.unknown,
      defaultOption.other,
    ],
  },
];

const SessionManagementPractices: FC = () => {
  const {
    control,
    formState: { errors },
  } = useForm<managementPracticesForm>({
    resolver: yupResolver(managementPracticesSchema),
  });
  const { session } = useStore((state) => state);
  /* const [btnDisabled, setBtnDisabled] = useState(true);
  const [isOwner, setIsOwner] = useState<boolean>(false);
  const { snackbarSetAlerts } = useStore((state) => state);

  useEffect(() => {
    setIsOwner(session?.owner_id === user?.user?.sub);
    !session?.form?.managementPractices &&
      snackbarSetAlerts([{
        message: " Le formulaire de pratique de gestion est incomplet",
        severity: "error",
        autoHideDuration: 10000,
      }]);
  }, [
    session?.uuid,
    user?.user?.sub,
  ]);

  const onSubmit: SubmitHandler<managementPracticesForm> = async (data) => {
    if (session?.uuid) {
      try {
        const sessionUpdated =
          await SessionsService.updateSessionSessionsSessionUuidPatch(
            session.uuid,
            {
              form: { ...session.form, managementPractices: { ...data } },
            }
          );
        setSession(sessionUpdated);
      } catch (e) {
        setBtnDisabled(false);
      } finally {
        setBtnDisabled(true);
        snackbarSetAlerts([
          {
            message:
              "Formulaire envoyé et complet, merci pour votre contribution",
            severity: "success",
          },
        ]);
      }
    }
  }; */

  return (
    <>
      <form>
        <Stack spacing={2}>
          {fields.map((selectField: fieldItem) =>
            selectField?.options ? (
              <FormControl key={selectField.name} fullWidth>
                <InputLabel>{selectField.label}</InputLabel>
                <Controller
                  defaultValue={
                    session?.form?.managementPractices
                      ? session?.form?.managementPractices?.[
                          selectField.name as keyof managementPracticesForm
                        ]
                      : ""
                  }
                  name={selectField.name as keyof managementPracticesForm}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      required
                      error={
                        !!errors[
                          selectField.name as keyof managementPracticesForm
                        ]
                      }
                      disabled
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        /* setBtnDisabled(false); */
                      }}
                      label={selectField.label}
                    >
                      {selectField?.options?.map((option, i) => {
                        return (
                          <MenuItem key={i} value={option.value}>
                            {option.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                />
              </FormControl>
            ) : (
              <Controller
                key={selectField.name}
                defaultValue={
                  session?.form?.managementPractices
                    ? session?.form?.managementPractices?.[
                        selectField.name as keyof managementPracticesForm
                      ]
                    : ""
                }
                name={selectField.name as keyof managementPracticesForm}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    required
                    disabled
                    value={value}
                    type="number"
                    onChange={(e) => {
                      onChange(e);
                      /* setBtnDisabled(false); */
                    }}
                    label={selectField.label}
                    inputProps={{ min: 0 }}
                  />
                )}
              />
            )
          )}

          {/* {isOwner ? (
            <Button type="submit" variant="contained" disabled={btnDisabled}>
              Envoyer
            </Button>
          ) : (
            <Alert variant="filled" severity="info" sx={{ color: "#000000DE" }}>
              Vous n'avez pas les droits d'édition sur cette session
            </Alert>
          )} */}
        </Stack>
      </form>
    </>
  );
};

export default SessionManagementPractices;
