import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { SvgIconComponent } from "@mui/icons-material";
import StarIcon from "@mui/icons-material/Star";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";

interface StatsProps {
  number?: number;
  name?: string;
  icon?: SvgIconComponent | ReactJSXElement;
}

const Stats: FC<StatsProps> = ({
  number = 0,
  name = "stat",
  icon = <StarIcon sx={{ fontSize: "40px", color: "primary.main" }} />,
}) => {
  return (
    <Stack direction="column" justifyContent="center" alignItems="center">
      <Stack direction="row">
        <>
          {icon}
          <Typography variant="h5" component="div">
            {number}
          </Typography>
        </>
      </Stack>
      <Stack>
        <Typography align="center" sx={{ mb: 1.5 }} color="text.secondary">
          {name}
        </Typography>
      </Stack>
    </Stack>
  );
};
export default Stats;
