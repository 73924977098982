import { GetState, SetState } from "zustand";
import { defaultRecords, GlobalState } from "./useStore";
import { DeviceReadAll, DevicesService } from "../client";

export interface DeviceState {
  devices: DeviceReadAll;
  devicesGetAll(): void;
}

const createDevices = (
  set: SetState<GlobalState>,
  get: GetState<GlobalState>
) => ({
  devices: defaultRecords,
  devicesGetAll: async () => {
    const devices = await DevicesService.allDevicesDevicesGet();
    set(() => ({ devices }));
  },
});

export default createDevices;
