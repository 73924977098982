/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { KeycloakUser } from '../models/KeycloakUser';
import type { UserGroup } from '../models/UserGroup';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UsersService {

    /**
     * All Users
     * @returns KeycloakUser Successful Response
     * @throws ApiError
     */
    public static allUsersUsersGet(): CancelablePromise<Array<KeycloakUser>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/',
        });
    }

    /**
     * Me
     * @returns UserGroup Successful Response
     * @throws ApiError
     */
    public static meUsersMeGet(): CancelablePromise<UserGroup> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/me',
        });
    }

}