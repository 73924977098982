import { FC } from "react";
import { List, ListItem, ListItemText } from "@mui/material";
import { translations } from "../../intl/translations";

export type SessionHabitatFormForm = Record<string, string>;

export interface SessionHabitatFormProps {
  form: Record<string, string>;
}

const SessionHabitatForm: FC<SessionHabitatFormProps> = ({ form }) => {
  const keys = Object.keys(form);

  return (
    <List sx={{ width: "100%" }}>
      {keys.map((key) => {
        const itemKey = `${form[0]}${
          Number(key) + 1
        }` as keyof typeof translations.fr.translation.habitats;

        let value = translations.fr.translation.habitats[itemKey];

        if (typeof value === "object") {
          const subKey = form[key] as keyof typeof value;
          value = translations.fr.translation.habitats[itemKey][subKey];
        }

        return (
          <ListItem dense key={key} alignItems="flex-start">
            <ListItemText
              primary={`Valeur ${Number(key) + 1}`}
              secondary={value}
            />
          </ListItem>
        );
      })}
    </List>
  );
};

export default SessionHabitatForm;
