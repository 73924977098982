import { FC, useEffect } from "react";
import { CircularProgress, Stack } from "@mui/material/";
import useStore from "../store/useStore";
import LayoutGallery from "../components/layout/Gallery";
import Filters from "../components/images/Filters";
import ListThumbnail from "../components/images/ListThumbnail";
import { useSearchParams } from "react-router-dom";
import ImageViewer from "../components/images/ImageViewer";
import ImageMetadata from "../components/images/ImageMetadata";
import { ImageFilter } from "../store/createImages";
import { SessionsService } from "../client";
import { saveData } from "../lib/saveData";
import axios from "axios";
import { LoadingButton } from "@mui/lab";

axios.interceptors.request.use(
  function (config) {
    if (!config.url?.startsWith("/api/v1/sessions/images/export")) {
      return config;
    }

    if (config.headers) {
      config.headers["Accept"] = "application/zip";
    }

    config.responseType = "blob";
    config.onDownloadProgress = function (e) {
      if (!e.total) {
        return;
      }
      const imagesExportingProgress = Math.round(
        ((e.loaded || 0) * 100) / e.total
      );

      useStore.setState({ imagesExportingProgress });
    };

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

const ActionsContainer: FC = () => {
  const { imageFilter, imagesExporting, imagesExportingProgress } = useStore(
    (state) => state
  );

  return (
    <Stack direction="row" spacing={2} justifyContent="flex-end">
      <LoadingButton
        color="secondary"
        variant="contained"
        loading={imagesExporting}
        loadingIndicator={
          <CircularProgress
            variant={
              imagesExportingProgress > 0 ? "determinate" : "indeterminate"
            }
            color="primary"
            value={imagesExportingProgress}
            size={16}
          />
        }
        onClick={async () => {
          useStore.setState({ imagesExporting: true });
          const zip =
            await SessionsService.exportSessionImagesSessionsImagesExportGet(
              imageFilter.session,
              imageFilter.site ? Number(imageFilter.site) : undefined,
            );

          saveData(zip, "export-images.zip");
          useStore.setState({
            imagesExporting: false,
            imagesExportingProgress: 0,
          });
        }}
      >
        Exporter
      </LoadingButton>
    </Stack>
  );
};

const GalleryPage: FC = () => {
  const { imagesGetAll, setImagesFilter, setImageSelected } = useStore(
    (state) => state
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const fetchData = async () => {
    await imagesGetAll([]);
  };

  useEffect(() => {
    const newImagesFilter = {
      group: searchParams.get("group") ?? "",
      site: searchParams.get("site") ?? "",
      session: searchParams.get("session") ?? "",
    };
    setImagesFilter(newImagesFilter);
    setImageSelected(undefined);
    fetchData();

    return () => {
      setImageSelected(undefined);
    };
  }, []);

  const handleOnFilterChange = async (
    type: keyof ImageFilter,
    values: Record<keyof ImageFilter, string>
  ) => {
    const newSearchParams: URLSearchParams = new URLSearchParams(values);
    setSearchParams(newSearchParams);
    await fetchData();
  };

  return (
    <LayoutGallery
      filters={<Filters onChange={handleOnFilterChange} />}
      images={<ListThumbnail />}
      viewer={<ImageViewer />}
      metadatas={<ImageMetadata />}
      actions={<ActionsContainer />}
    />
  );
};

export default GalleryPage;
