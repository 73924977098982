import { FC, useState } from "react";
import {
  Stack,
  GlobalStyles,
  Typography,
  Tooltip,
  Button,
  Grid,
  Box,
} from "@mui/material/";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import IconInfo from "@mui/icons-material/Info";
import CalendarMoon from "../components/calendar/CalendarMoon";

const calendarGlobalStyles = (
  <GlobalStyles
    styles={{
      ".react-calendar__tile": {
        height: "120px",
        width: "120px",
        border: "0.3em solid white",
      },
      "@media screen and (max-width: 640px)": {
        ".react-calendar__tile": {
          height: "auto",
          width: "auto",
          borderRadius: "50px",
          border: "5px solid white",
          padding: "5px",
        },
      },
    }}
  />
);

const styles = {
  buttonData: {
    "&:hover": {
      backgroundColor: "secondary.dark",
    },
    color: "text.primary",
    fontWeight: "bold",
  },
};

const calendarMonths = () => {
  const calendars: JSX.Element[] = [];
  for (let i = 0; i < 12; i++) {
    calendars.push(
      <CalendarMoon
        showNeighboringMonth={false}
        prevLabel={null}
        nextLabel={null}
        activeStartDate={new Date(new Date().getFullYear(), i, 1)}
        showFixedNumberOfWeeks={true}
        borderTiles={true}
      />
    );
  }
  return calendars;
};

const CalendarPage: FC = () => {
  const [month, setMonth] = useState<boolean>(true);

  return (
    <Stack spacing={2} sx={{ flex: 1 }}>
      <Grid
        container
        spacing={2}
        alignItems="center"
        sx={{ display: "grid", gridTemplateColumns: "1fr repeat(2, auto) 1fr" }}
      >
        <Grid item gap={4} sx={{ display: "flex", gridColumnStart: "1" }}>
          <Typography variant="h3">Bonnes dates</Typography>
          <Tooltip
            placement="bottom-start"
            title="La période la plus propice correspond à la nouvelle lune et aux jours alentours."
          >
            <IconInfo color="disabled" />
          </Tooltip>
        </Grid>
        <Grid item>
          <Button
            disableElevation
            variant="outlined"
            color="secondary"
            sx={[
              { bgcolor: month ? "secondary.main" : "secondary.light" },
              styles.buttonData,
            ]}
            onClick={() => setMonth(true)}
          >
            Mois
          </Button>
          <Button
            disableElevation
            variant="outlined"
            color="secondary"
            sx={[
              { bgcolor: month ? "secondary.light" : "secondary.main" },
              styles.buttonData,
            ]}
            onClick={() => setMonth(false)}
          >
            Année
          </Button>
        </Grid>
      </Grid>

      {month ? (
        <>
          {calendarGlobalStyles}
          <CalendarMoon
            showNeighboringMonth={true}
            prevLabel={<ArrowBackIosIcon />}
            nextLabel={<ArrowForwardIosIcon />}
            displayWeeks={true}
          />
        </>
      ) : (
        <Grid container spacing={1}>
          {calendarMonths().map((month: JSX.Element, i) => (
            <Grid key={i} item xs={12} sm={6} md={4} lg={3}>
              {month}
            </Grid>
          ))}
        </Grid>
      )}
      <Box>
        <Typography paragraph>
          Pour réaliser une session Lepinoc, vous aurez 6 jours avant la
          nouvelle lune et 4 jours après. La nouvelle lune elle-même est à
          éviter !
        </Typography>
        <Typography paragraph>
          Lépinoc devant se réaliser par beau temps, vous pouvez consulter la
          météo à l’aide de <a href="https://meteofrance.com/">Météofrance</a>.
        </Typography>
        <Typography paragraph>
          Afin de préparer cette session, pensez à bien charger la batterie de
          votre dispositif et votre smartphone. Le jour J, n’oubliez pas vos
          outils (clef, tournevis) pour installer le dispositif !
        </Typography>
        <Typography paragraph>
          Pour rappel, vous devez lancer votre nouvelle session à l’aide de
          l’application seulement quand vous vous trouvez à l’emplacement du
          dispositif, sinon les coordonnées GPS seront fausses.
        </Typography>
        <Typography paragraph>
          Besoin d’un rappel pour une des étapes du protocole ? Pas
          d’inquiétude, vous pouvez consulter :
        </Typography>
        <Typography component="div">
          <ul>
            <li>
              <a href="https://noe.org/media/notice-lepinoc-2022-vf.pdf">
                La notice Lépinoc
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/watch?v=wkGQDTcOmOc&feature=youtu.be">
                La vidéo d’installation du dispositif
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/watch?v=RxN1MZh-zDI">
                La vidéo d’utilisation de l’application et de la plateforme web
              </a>
            </li>
            <li>
              <a href="https://noe.org/media/missions/faq-le-pinoc-2022-vdef.pdf">
                La FAQ
              </a>
            </li>
          </ul>
        </Typography>
        <Typography paragraph>
          Si vous avez d’autres questions, n’hésitez pas à nous contacter à
          l’adresse suivante :{" "}
          <a href="mailto:lepinoc@noe.org">lepinoc@noe.org</a>
        </Typography>
        <Typography paragraph>Bonne session et à bientôt !</Typography>
        <Typography paragraph>L’équipe Lépinoc</Typography>
      </Box>
    </Stack>
  );
};

export default CalendarPage;
