/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ImageRead } from '../models/ImageRead';
import type { ImageReadAll } from '../models/ImageReadAll';
import type { SessionCreate } from '../models/SessionCreate';
import type { SessionRead } from '../models/SessionRead';
import type { SessionReadAll } from '../models/SessionReadAll';
import type { SessionReadAllShort } from '../models/SessionReadAllShort';
import type { SessionUpdate } from '../models/SessionUpdate';
import type { SessionUsers } from '../models/SessionUsers';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SessionsService {

    /**
     * All Sessions
     * @param query
     * @param complete
     * @returns any Successful Response
     * @throws ApiError
     */
    public static allSessionsSessionsGet(
        query?: Array<string>,
        complete: boolean = true,
    ): CancelablePromise<(SessionReadAll | SessionReadAllShort)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sessions/',
            query: {
                'query': query,
                'complete': complete,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create One Session
     * @param requestBody
     * @returns SessionRead Successful Response
     * @throws ApiError
     */
    public static createOneSessionSessionsPost(
        requestBody: SessionCreate,
    ): CancelablePromise<SessionRead> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/sessions/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Export
     * @param query
     * @returns any Successful Response
     * @throws ApiError
     */
    public static exportSessionsExportGet(
        query?: Array<string>,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sessions/export',
            query: {
                'query': query,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Export Admin
     * @returns any Successful Response
     * @throws ApiError
     */
    public static exportAdminSessionsExportAdminGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sessions/export/admin',
        });
    }

    /**
     * Get Session Images
     * @param query
     * @param sessionUuid
     * @param siteId
     * @returns ImageReadAll Successful Response
     * @throws ApiError
     */
    public static getSessionImagesSessionsImagesGet(
        query?: Array<string>,
        sessionUuid?: string,
        siteId?: number,
    ): CancelablePromise<ImageReadAll> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sessions/images',
            query: {
                'query': query,
                'session_uuid': sessionUuid,
                'site_id': siteId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Sessions Users
     * @returns SessionUsers Successful Response
     * @throws ApiError
     */
    public static getAllSessionsUsersSessionsUsersGet(): CancelablePromise<SessionUsers> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sessions/users',
        });
    }

    /**
     * Export Session Images
     * @param sessionUuid
     * @param siteId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static exportSessionImagesSessionsImagesExportGet(
        sessionUuid?: string,
        siteId?: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sessions/images/export',
            query: {
                'session_uuid': sessionUuid,
                'site_id': siteId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Session
     * @param sessionUuid
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getSessionSessionsSessionUuidGet(
        sessionUuid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sessions/{session_uuid}',
            path: {
                'session_uuid': sessionUuid,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Session
     * @param sessionUuid
     * @returns ImageRead Successful Response
     * @throws ApiError
     */
    public static deleteSessionSessionsSessionUuidDelete(
        sessionUuid: string,
    ): CancelablePromise<Array<ImageRead>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/sessions/{session_uuid}',
            path: {
                'session_uuid': sessionUuid,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Session
     * @param sessionUuid
     * @param requestBody
     * @returns SessionRead Successful Response
     * @throws ApiError
     */
    public static updateSessionSessionsSessionUuidPatch(
        sessionUuid: string,
        requestBody: SessionUpdate,
    ): CancelablePromise<SessionRead> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/sessions/{session_uuid}',
            path: {
                'session_uuid': sessionUuid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}