import { FC, useState } from "react";
import { Box, Button, Stack, Tab, TextField, Tooltip } from "@mui/material";
import { useForm, SubmitHandler, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ContainerTitle from "../commons/ContainerTitle";
import CommonsMultiSelect from "../commons/MultiSelect";
import useStore from "../../store/useStore";
import { useNavigate } from "react-router-dom";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import CreateManagementPractices from "./CreateManagementPractices";
import CreateHabitatTabs from "./CreateHabitatTabs";

export type SiteForm = {
  name: string;
  coordinates: string;
  area: string;
  organisation: string;
  user: string;
  shared: Array<string>;
  seeding: string;
  cuttingType: string;
  cuttingPeriod?: string;
  cuttingFrequency?: string;
  residueExportation?: string;
  pastureType: string;
  pasturePressure?: number;
  pastureSize?: number;
  pastureDuration?: number;
  phytosanitary: string;
  uncontrolledManagementPressure: string;
  habitats: Record<"id", string>[]
};

const schema = yup.object()
  .shape({
    name: yup.string().required("Le nom du site est requis"),
    seeding: yup.string().required(),
    cuttingType: yup.string().required(),
    cuttingPeriod: yup
      .string()
      .when("cuttingType", (cuttingType: string, schema: any) => {
        if (["c", "z"].includes(cuttingType)) {
          return schema.optional();
        }
        return schema.required();
      }),
    cuttingFrequency: yup
      .string()
      .when("cuttingType", (cuttingType: string, schema: any) => {
        if (["c", "z"].includes(cuttingType)) {
          return schema.optional();
        }
        return schema.required();
      }),
    residueExportation: yup.string()
      .when("cuttingType", (cuttingType: string, schema: any) => {
        if (["c", "z"].includes(cuttingType)) {
          return schema.optional();
        }
        return schema.required();
      }),
    pastureType: yup.string().required(),
    pasturePressure: yup
      .number()
      .when("pastureType", (pastureType: string, schema: any) => {
        if (["e", "z"].includes(pastureType)) {
          return schema.optional();
        }
        return schema.required();
      }),
    pastureSize: yup.number()
      .when("pastureType", (pastureType: string, schema: any) => {
        if (["e", "z"].includes(pastureType)) {
          return schema.optional();
        }
        return schema.required();
      }),
    pastureDuration: yup.number()
      .when("pastureType", (pastureType: string, schema: any) => {
        if (["e", "z"].includes(pastureType)) {
          return schema.optional();
        }
        return schema.required();
      }),
    phytosanitary: yup.string().required(),
    uncontrolledManagementPressure: yup.string().required(),
    habitats: yup
      .array()
      .of(yup.array().of(yup.string().required("Ce champs est requis."))),
  })

interface SiteAddProps {
  onCancel?(): void;
}

const SiteAdd: FC<SiteAddProps> = ({ onCancel }) => {
  const { siteAdd, siteTemporal, setSiteTemporal, groupsOthers } = useStore(
    (state) => state
  );
  const formApi = useForm<SiteForm>({ resolver: yupResolver(schema) });

  const navigate = useNavigate();

  const [value, setValue] = useState<string>("habitatTabs");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const onSubmit: SubmitHandler<SiteForm> = async (site) => {
    if (siteTemporal) {
      const newSite = await siteAdd({
        name: site.name,
        geom: siteTemporal.geom,
        centroid: siteTemporal.centroid,
        area: siteTemporal.area,
        department: siteTemporal.department!,
        shared_ids: siteTemporal.shared_ids,
        management_practices: {
          seeding: site.seeding,
          cuttingType: site.cuttingType,
          cuttingPeriod: site.cuttingPeriod,
          cuttingFrequency: site.cuttingFrequency,
          residueExportation: site.residueExportation,
          pastureType: site.pastureType,
          pasturePressure: site.pasturePressure,
          pastureSize: site.pastureSize,
          pastureDuration: site.pastureDuration,
          phytosanitary: site.phytosanitary,
          uncontrolledManagementPressure: site.uncontrolledManagementPressure
        },
        habitats: site.habitats
      });

      navigate(`/sites/${newSite.uuid}`, {
        replace: true,
      });
    }
  };

  return (
    <ContainerTitle title="AJOUTER UN SITE">
      <form
        onSubmit={formApi.handleSubmit(onSubmit)}
        style={{ height: "100%", display: "flex" }}
      >
        <Stack
          spacing={2}
          direction="column"
          alignItems="stretch"
          sx={{ flexGrow: 1 }}
        >
          <TextField
            {...formApi.register("name")}
            name="name"
            label="Nom du site"
            variant="filled"
            defaultValue={""}
            error={Boolean(formApi.formState.errors.name)}
            helperText={formApi.formState.errors.name?.message}
            fullWidth
          />
          <TextField
            name="department"
            label="Département"
            variant="filled"
            InputProps={{ readOnly: true }}
            value={siteTemporal?.department}
            fullWidth
          />
          <TextField
            name="area"
            label="Aire calculée (m²)"
            variant="filled"
            InputProps={{ readOnly: true }}
            value={siteTemporal?.area}
            fullWidth
          />
          <TextField
            name="coordinates"
            label="Coordonnées du centroïde"
            variant="filled"
            InputProps={{ readOnly: true }}
            value={
              "Latitude: " +
              siteTemporal?.centroid.coordinates[1] +
              "°N; Longitude: " +
              siteTemporal?.centroid.coordinates[0] +
              "°E"
            }
            fullWidth
          />

          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handleChange} aria-label="site info tabs">
                <Tab key={"habitatTabs"} label="Formulaires Habitats" value={"habitatTabs"} />
                <Tab key={"management_practices"} label="Pratiques de gestion" value={"management_practices"} />
              </TabList>
            </Box>
            <TabPanel
              key={"habitatTabs"}
              value={"habitatTabs"}
              sx={{ p: 1, marginTop: "0 !important" }}
            >
              <CreateHabitatTabs formApi={formApi}></CreateHabitatTabs>
            </TabPanel>
            <TabPanel
              key={"management_practices"}
              value={"management_practices"}
              sx={{ p: 1, marginTop: "0 !important" }}
            >
              <CreateManagementPractices formApi={formApi}></CreateManagementPractices>
            </TabPanel>
          </TabContext>

          <Box sx={{ flexGrow: 1 }}>
            <Stack direction="row" justifyContent="end" sx={{ padding: 1 }}>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "transparent",
                  color: "#000000",
                  boxShadow: "none",
                }}
                onClick={() => {
                  siteTemporal?.layer.remove();
                  onCancel && onCancel();
                }}
              >
                Annuler
              </Button>

              <Button
                type="submit"
                variant="contained"
                style={{
                  boxShadow: "none",
                }}
              >
                Confirmer
              </Button>
            </Stack>
          </Box>
        </Stack>
      </form>
    </ContainerTitle>
  );
};

export default SiteAdd;
