import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { ReactNode, useEffect, useRef, useState } from "react";
import useStore from "../../store/useStore";
import HabitatTabs from "./HabitatTabs";
import ManagementPractices from "./ManagementPractices";
import { useSearchParams } from "react-router-dom";

interface InfoTabsTabsProps {
  label: string;
  component: ReactNode;
}
type InfoTabsTabs = Record<string, InfoTabsTabsProps>;

const tabs: InfoTabsTabs = {
  habitatTabs: {
    label: "Formulaires Habitats",
    component: <HabitatTabs />,
  },
  management_practices: {
    label: "Pratiques de gestion",
    component: <ManagementPractices />,
  },
};

const InfoTabs = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tabsRef = useRef(null);
  let tabKeys = Object.keys(tabs);
  const { site } = useStore((state) => state);

  const defaultTab = tabKeys[0];
  const [value, setValue] = useState<string>(defaultTab!);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (
      tabsRef.current &&
      searchParams.get("details") &&
      searchParams.get("details") === "management-practices"
    ) {
      setValue("management_practices");
      (tabsRef.current as HTMLElement).scrollIntoView();
    }
  }, [searchParams]);

  return (
    <TabContext value={value}>
      <Box ref={tabsRef} sx={{ borderBottom: 1, borderColor: "divider" }}>
        <TabList onChange={handleChange} aria-label="site info tabs">
          {tabKeys.map((key) => (
            <Tab key={key} label={tabs[key].label} value={key} />
          ))}
        </TabList>
      </Box>
      {tabKeys.map((key) => (
        <TabPanel
          key={key}
          value={key}
          sx={{ p: 1, marginTop: "0 !important" }}
        >
          {tabs[key].component}
        </TabPanel>
      ))}
    </TabContext>
  );
};

export default InfoTabs;
