import { Stack } from "@mui/material";
import { FC } from "react";
import SiteImages from "./Images";
import SiteMetadata from "./Metadata";
import SiteEdition from "./Edition";
import LastSessions from "../session/LastSessions";
import useStore from "../../store/useStore";
import { handleGoBack } from "../../lib/navigateBack";
import { useNavigate } from "react-router-dom";
import InfoTabs from "./InfoTabs";
interface SiteInfoLayoutProps {
  onCancel?(): void;
}

const SiteInfo: FC<SiteInfoLayoutProps> = ({ onCancel }) => {
  const sessions = useStore((state) => state.site?.sessions);
  const navigate = useNavigate();
  return (
    <Stack
      spacing={2}
      sx={{ height: { md: "calc(100vh - 64px)", xs: "calc(50vh - 64px)" } }}
    >
      <SiteEdition onCancel={() => handleGoBack("/sites", navigate)} />
      <SiteMetadata />
      <LastSessions sessions={sessions} number={3} />
      <SiteImages />
      <InfoTabs />
    </Stack>
  );
};

export default SiteInfo;
