import { List, ListItem, ListItemText } from "@mui/material";
import { FC, isValidElement, ReactNode } from "react";

export interface RecordMetadataItem {
  primary: string;
  secondary: string | ReactNode;
  visible?: boolean;
}

export interface CommonsMetadataListProps {
  items: (RecordMetadataItem | ReactNode)[];
}

const CommonsMetadataList: FC<CommonsMetadataListProps> = ({ items }) => {
  return (
    <List>
      {items.map((item, i) => {
        const element = isValidElement(item) ? (
          item
        ) : (item as RecordMetadataItem).visible === false ? null : (
          <ListItemText
            primary={(item as RecordMetadataItem).primary}
            secondary={(item as RecordMetadataItem).secondary}
          />
        );

        return (
          <ListItem key={i} divider={true}>
            {element}
          </ListItem>
        );
      })}
    </List>
  );
};

export default CommonsMetadataList;
