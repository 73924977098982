import { FC, ReactNode, useEffect } from "react";
import { useLocation } from "react-router-dom";

export interface CommonsScrollToTopProps {
  children?: ReactNode;
}

const CommonsScrollToTop: FC<CommonsScrollToTopProps> = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return <>{children}</>;
};

export default CommonsScrollToTop;
