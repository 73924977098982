import { FC, useCallback, useEffect, useState } from "react";
import useStore from "../store/useStore";
import { Stack, Typography } from "@mui/material";
import SessionList from "../components/session/List";
import SessionFilters from "../components/session/Filters";
import { SessionFilter } from "../store/createSessions";
import { useSearchParams } from "react-router-dom";
import { SessionRead } from "../client";
import CommonAlertDialog, {
  DialogState,
} from "../components/commons/AlertDialog";

export interface SessionDialogState extends DialogState {
  sessionToDelete?: SessionRead;
}

const defaultDialogState = {
  open: false,
  loading: false,
  sessionToDelete: undefined,
};

const SessionsPage: FC = () => {
  const {
    setSessionFilter,
    sessionsGet,
    sessionUsersGet,
    groupGetAll,
    sitesGet,
    sessionDelete,
    sessionUsers,
    devicesGetAll,
  } = useStore((state) => state);
  const [searchParams, setSearchParams] = useSearchParams();
  const [dialogState, setDialogState] =
    useState<SessionDialogState>(defaultDialogState);

  const fetchData = useCallback(async () => {
    await groupGetAll();
    await devicesGetAll();
    await sessionUsersGet();
    await sitesGet(["limit(bypass)"]);
  }, [devicesGetAll, sitesGet, groupGetAll, sessionUsers]);

  useEffect(() => {
    fetchData().then(() => {
      const newFilters: SessionFilter = {
        uuid: searchParams.get("uuid") ?? "",
        sites: searchParams.get("sites")
          ? (searchParams.get("sites")?.split(",").map(Number) as number[])
          : [],
        devices: searchParams.get("devices")
          ? (searchParams.get("devices")?.split(",").map(Number) as number[])
          : [],
        startDate:
          searchParams.get("startDate") &&
          searchParams.get("startDate") !== "undefined" &&
          searchParams.get("startDate") !== null
            ? new Date(searchParams.get("startDate")!)
            : undefined,
        endDate:
          searchParams.get("endDate") &&
          searchParams.get("endDate") !== "undefined" &&
          searchParams.get("endDate") !== null
            ? new Date(searchParams.get("endDate")!)
            : undefined,
        users: searchParams.get("users")
          ? searchParams.get("users")?.split(",")
          : [],
        currentPage: searchParams.get("currentPage")
          ? Number(searchParams.get("currentPage"))
          : 0,
        rowsPerPage: searchParams.get("rowsPerPage")
          ? Number(searchParams.get("rowsPerPage"))
          : 10,
      };

      setSessionFilter(newFilters);
      sessionsGet([
        `limit(${newFilters.rowsPerPage}, ${
          newFilters.currentPage * newFilters.rowsPerPage
        })`,
      ]);
    });
  }, [searchParams]);

  const handleFilter = async (filters: SessionFilter) => {
    const newSearchParams: URLSearchParams = new URLSearchParams({
      ...(filters as unknown as Record<string, string>),
      startDate: filters.startDate?.toISOString()!,
      endDate: filters.endDate?.toISOString()!,
    });
    setSearchParams(newSearchParams);
  };

  const handleDeleted = (session: SessionRead) => {
    setDialogState({ ...dialogState, open: true, sessionToDelete: session });
  };

  const handleAgree = async () => {
    if (dialogState.sessionToDelete) {
      setDialogState({ ...dialogState, loading: true });
      await sessionDelete(dialogState.sessionToDelete);
    }
    handleDisagree();
  };

  const handleDisagree = () => {
    setDialogState(defaultDialogState);
  };

  return (
    <Stack spacing={2}>
      <Typography align="center" variant="h3">
        Sessions
      </Typography>
      <SessionFilters onChange={handleFilter} />
      <SessionList onDeleted={handleDeleted} />
      <CommonAlertDialog
        title={"Attention !"}
        content={[
          "Supprimer cette session supprimera également toutes les images associées",
        ]}
        agreeLabel={"Oui je suis sûr"}
        disagreeLabel={"Non, j'ai changé d'avis"}
        open={dialogState.open}
        loading={dialogState.loading}
        onAgree={handleAgree}
        onDisagree={handleDisagree}
      />
    </Stack>
  );
};

export default SessionsPage;
