import { FC } from "react";
import {
  ArrayField,
  Datagrid,
  DateField,
  DateInput,
  FunctionField,
  List,
  TextField,
  TextInput,
  WithListContext,
  useInput,
  useListContext,
} from "react-admin";
import { Typography } from "@mui/material";
import { AdminJsonFieldValue } from "./AdminJsonFieldValue";

const TitleInput: FC<{ source: string; label: string }> = ({
  source,
  label,
}) => {
  const { id, field, fieldState } = useInput({ source });
  const { filterValues, displayedFilters, setFilters } = useListContext();

  return (
    <div
      onClick={() => {
        if (filterValues[`${source}@_is_null`]) {
          delete filterValues[`${source}@_is_null`];
          /* setFilters(
            { ...filterValues, [`${source}@_neq`]: "" },
            {}
          ); */
        } else {
          delete filterValues[`${source}@_neq`];
          setFilters({ ...filterValues, [`${source}@_is_null`]: true }, {});
        }
      }}
    >
      Val: {field.value}
    </div>
  );
};

const filters = [
  //<TitleInput label="AAA" source="habitats" />,
  <TextInput label="Nom" source="name@_ilike" />,
  <DateInput label="Date de création =" source="created_date" />,
  <DateInput label="Date de création >=" source="created_date@_gte" />,
  <DateInput label="Date de création <=" source="created_date@_lte" />,
  <TextInput label="Département" source="department@_ilike" />,
  <TextInput label="Owner" source="owner#username@_ilike" />,
  <TextInput label="ID =" source="id" />,
  <TextInput label="ID >=" source="id@_gte" />,
  <TextInput label="ID <=" source="id@_lte" />,
  <TextInput label="UUID" source="uuid" />,
];

interface IProps {}

export const AdminSiteList: FC<IProps> = (props) => {
  return (
    <List filters={filters} exporter={false}>
      <Datagrid bulkActionButtons={false} rowClick="show">
        <TextField source="id" label="ID" />
        <TextField source="name" label="Nom" />
        <DateField source="created_date" label="Créé le" />
        <TextField source="department" label="Département" />
        <TextField source="area" />
        <FunctionField
          label="Geom"
          render={(record?: any) => (
            <AdminJsonFieldValue value={record?.geom} />
          )}
        />
        <ArrayField source="shareds" label="Invité" perPage={1}>
          <WithListContext
            render={(params) => {
              const nbMore = params.total - params.data.length;
              const perPage = 1;

              return (
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    params.setPerPage(
                      params.perPage != perPage ? perPage : 999999
                    );
                  }}
                >
                  {params.data.map((shared) => shared.group?.name).join(", ")}
                  {nbMore > 0 && ` +${nbMore}`}
                </span>
              );
            }}
          />
        </ArrayField>
        <FunctionField
          label="Centroïd"
          render={(record?: any) => {
            return (record?.centroid?.coordinates || []).map(
              (value: number, i: number) => {
                return <Typography key={`${value}${i}`}>{value}</Typography>;
              }
            );
          }}
        />
        <TextField source="owner.username" label="Owner" />
        <TextField source="uuid" label="UUID" />
        <FunctionField
          label="Habitats"
          render={(record?: any) => (
            <AdminJsonFieldValue value={record?.habitats} />
          )}
        />
        <FunctionField
          label="Pratiques de gestion"
          render={(record?: any) => (
            <AdminJsonFieldValue value={record?.management_practices} />
          )}
        />
      </Datagrid>
    </List>
  );
};
