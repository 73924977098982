import { FC } from "react";
import { Typography, Container, Box } from "@mui/material";

const styles = {
  heroContainer: {
    backgroundImage: 'url("/assets/hero.png")',
    backgroundSize: "cover",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    height: { md: 640, xs: 320 },
    p: 8,
    color: "primary.contrastText",
  },
};

const HomeHero: FC = () => {
  return (
    <Box sx={styles.heroContainer}>
      <Container>
        <Typography variant="h2" gutterBottom={true}>
          Protocole participatif
        </Typography>
        <Typography variant="h2">de suivi des papillons de nuit</Typography>
      </Container>
    </Box>
  );
};

export default HomeHero;
