import { FC, useState } from "react";
import { List, ListItem, ListItemText, Tabs, Tab, Box } from "@mui/material";
import useStore from "../../store/useStore";
import ContainerTitle from "../commons/ContainerTitle";
import SessionMetadata from "../session/Metadata";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const ImageMetadata: FC = () => {
  const { imageSelected } = useStore((state) => state);
  const [value, setValue] = useState(0);
  const exif = imageSelected?.exif;

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <ContainerTitle title="Métadonnées">
      <Box sx={{ width: "100%", maxHeight: "70vh", overflow: "auto" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Session" {...a11yProps(0)} />
            {/* <Tab label="Photo" {...a11yProps(1)} /> */}
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <SessionMetadata hasTitle={false} />
        </TabPanel>
        {/* {exif && (
          <TabPanel value={value} index={1}>
            <List>
              {Object.keys(exif).map((key, i) => (
                <ListItem key={i} divider={true}>
                  <ListItemText
                    primary={key}
                    secondary={JSON.stringify(exif[key as keyof object])}
                  />
                </ListItem>
              ))}
            </List>
          </TabPanel>
        )} */}
      </Box>
    </ContainerTitle>
  );
};
export default ImageMetadata;
