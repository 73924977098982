import { GetState, SetState } from "zustand";
import { GlobalState } from "./useStore";

export interface MapState {
  editing: boolean;
  toggleEditing(): void;
}

const createMap = (set: SetState<GlobalState>, get: GetState<GlobalState>) => ({
  editing: false,
  toggleEditing: () => set((state) => ({ editing: !state.editing })),
});

export default createMap;
