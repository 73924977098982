import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";

declare module "@mui/material/styles" {
  interface Theme { }
  // allow configuration using `createTheme`
  interface ThemeOptions { }
}

// A custom theme for this app
const theme = createTheme({
  typography: {
    h1: {
      fontFamily: "Tellural",
      fontSize: "1rem",
      fontWeight: 700,
    },
    h2: {
      fontFamily: "Tellural",
      fontSize: "2rem",
    },
    h3: {
      fontFamily: "Tellural",
      textTransform: "uppercase",
      fontSize: "1.2rem",
      fontWeight: 700,
    },
    h4: {
      fontFamily: "Tellural",
    },
    h5: {
      fontSize: '2rem',
    },
    body1: { fontWeight: 500 },
    body2: { fontWeight: 400 },
  },
  palette: {
    primary: {
      light: "#2196F314",
      main: "#4BAFE8",
      dark: "#1E1D4B",
      contrastText: "#ffff",
    },
    secondary: {
      main: "#F2CC38",
      dark: "#e6b115",
      light: "#F2CC3814",
      contrastText: "#181736",
    },
    error: {
      main: red.A400,
    },
    info: {
      main: "#f3f3f3",
      dark: "#cdcdcd",
      contrastText: "#000000DE",
    },
    text: {
      primary: "#000000DE",
    },
    action: {
      hover: "#2196F314",
      selected: "#F2CC3814",
    },
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: '700',
        },
      },
    },
  }
});

export default theme;
