import { Box, ListItemText, Stack, Typography } from "@mui/material";
import { FC } from "react";

interface CommonsListItemCoordinatesProps {
  lat: number;
  long: number;
}

const CommonsListItemCoordinates: FC<CommonsListItemCoordinatesProps> = ({
  lat,
  long,
}) => {
  return (
    <ListItemText
      primary="Coordonnées géographiques"
      secondary={
        <Stack>
          <Typography>Latitude: {lat?.toFixed(3)}°N</Typography>
          <Typography>Longitude: {long?.toFixed(3)}°E</Typography>
        </Stack>
      }
      secondaryTypographyProps={{ component: Box }}
    />
  );
};

export default CommonsListItemCoordinates;
