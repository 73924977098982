import { TabPanel } from "@mui/lab";
import { Box, MenuItem, Stack, Typography } from "@mui/material";
import { FC, useEffect, useRef } from "react";
import { HookFormSelect } from "../commons/HookFormSelect";
import { UseFormReturn } from "react-hook-form";
import { translations } from "../../intl/translations";
import HookFormHiddenField from "../commons/HookFormHiddenField";
import { SiteForm } from "./Add";

export const HABITATS: {
  key: string;
  nbRowsInCols: any[];
}[] = [
    { key: "A", nbRowsInCols: [4, 0, 0] },
    { key: "B", nbRowsInCols: [11, 0, 0] },
    { key: "C", nbRowsInCols: [6, 0, ["a", "d", "e", "f", "g"]] },
    { key: "D", nbRowsInCols: [5, 6, 5] },
    { key: "E", nbRowsInCols: [4, 0, 6] },
  ];

const getHabitatRowKey = (colIndex: number, rowIndex: number) => {
  const rowNum = rowIndex + 1;
  if (colIndex % 2) {
    return rowNum.toString();
  }
  return String.fromCharCode(rowNum + 64).toLowerCase();
};

interface IProps {
  index: number;
  formApi: UseFormReturn;
}

export const HabitatTab: FC<IProps> = (props) => {
  const { index, formApi } = props;
  const habitatKey = formApi.watch(`habitats.${index}.0`);
  const habitatKeyRef = useRef(habitatKey);
  const habitat = HABITATS.find((habitat) => {
    return habitat.key === habitatKey;
  });

  useEffect(() => {
    if (habitatKeyRef.current != habitatKey) {
      habitat?.nbRowsInCols.forEach((nbRowsInCol, i) => {
        formApi.setValue(
          `habitats.${index}.${i + 1}`,
          !Boolean(nbRowsInCol) ? "0" : ""
        );
      });
      habitatKeyRef.current = habitatKey;
    }
  }, [habitatKey]);

  return (
    <TabPanel sx={{ p: 0 }} value={String(index)}>
      <Stack key={index} spacing={3} pt={2}>
        <HookFormSelect
          formApi={formApi}
          name={`habitats.${index}.0`}
          selectProps={{
            label: "Valeur 1",
          }}
        >
          {HABITATS.map((habitat) => {
            return (
              <MenuItem key={habitat.key} value={habitat.key}>
                {habitat.key}.{" "}
                {
                  (translations.fr.translation.habitats as any)[
                  `${habitat.key}1`
                  ]
                }
              </MenuItem>
            );
          })}
        </HookFormSelect>
        {Boolean(habitatKey) &&
          habitat?.nbRowsInCols.map((nbRowsInCol, colIndex) => {
            const colNum = colIndex + 2;
            if (!Boolean(nbRowsInCol)) {
              return (
                <Box key={habitatKey + colIndex}>
                  <Typography>{`Valeur ${colNum}`}</Typography>
                  <Typography>Aucune valeur à sélectionner</Typography>
                  <HookFormHiddenField
                    formApi={formApi}
                    name={`habitats.${index}.${colIndex + 1}`}
                    defaultValue="0"
                  />
                </Box>
              );
            }
            const hasRowValues = Array.isArray(nbRowsInCol);
            const rows = hasRowValues
              ? nbRowsInCol
              : Array.from(Array(nbRowsInCol).keys());
            return (
              <Box key={habitatKey + colIndex}>
                <HookFormSelect
                  formApi={formApi}
                  name={`habitats.${index}.${colIndex + 1}`}
                  selectProps={{
                    label: `Valeur ${colNum}`,
                  }}
                >
                  {rows.map((rowValue, rowIndex) => {
                    const rowKey = hasRowValues
                      ? rowValue
                      : getHabitatRowKey(colIndex, rowIndex);
                    return (
                      <MenuItem
                        key={habitatKey + colIndex + rowIndex}
                        value={rowKey}
                      >
                        {rowKey}.{" "}
                        {
                          (translations.fr.translation.habitats as any)[
                          `${habitatKey}${colNum}`
                          ][rowKey]
                        }
                      </MenuItem>
                    );
                  })}
                </HookFormSelect>
              </Box>
            );
          })}
      </Stack>
    </TabPanel>
  );
};
