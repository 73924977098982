import { createRoot } from "react-dom/client";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { AuthClientTokens } from "@react-keycloak/core/lib/types";
import "./index.css";
import App from "./App";
import React, { createRef } from "react";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import theme from "./theme/theme";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import keycloak from "./keycloak";
import useStore from "./store/useStore";
import { SnackbarProvider } from "notistack";

Sentry.init({
  dsn: "https://13b5c47513554f92989caec4697a57be@sentry02.natural-solutions.eu/3",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const handleTokens = (tokens: AuthClientTokens) => {
  if (!tokens.idToken && !tokens.refreshToken && !tokens.token) {
    localStorage.removeItem("lepinoc-id-token");
    localStorage.removeItem("lepinoc-access-token");
    localStorage.removeItem("lepinoc-refresh-token");
  }

  if (tokens.idToken && tokens.refreshToken && tokens.token) {
    localStorage.setItem("lepinoc-id-token", tokens.idToken);
    localStorage.setItem("lepinoc-access-token", tokens.token);
    localStorage.setItem("lepinoc-refresh-token", tokens.refreshToken);

    if (!useStore.getState().user) {
      useStore.getState().setCurrentUser();
    }
  }
};

const rootElement = document.getElementById("root");

if (!rootElement) throw new Error("Failed to find the root element");

const root = createRoot(rootElement);
const notistackRef: React.RefObject<SnackbarProvider> = createRef();
const onClickDismiss = (key: any) => () => {
  notistackRef?.current?.closeSnackbar(key);
};

root.render(
  <ReactKeycloakProvider authClient={keycloak} onTokens={handleTokens}>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider
          maxSnack={3}
          ref={notistackRef}
          action={(key) => (
            <CloseIcon
              sx={{ cursor: "pointer" }}
              onClick={onClickDismiss(key)}
            />
          )}
        >
          <App />
        </SnackbarProvider>
      </ThemeProvider>
    </BrowserRouter>
  </ReactKeycloakProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
