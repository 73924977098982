import { FC, useState } from "react";
import {
  Stack,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { SiteForm } from "./Add";

export interface fieldItem {
  name: string;
  label: string;
  options?: optionItem[];
  facultative?: boolean;
  enable: boolean;
}

interface optionItem {
  label: string;
  value: string;
}

const defaultOption: { [key: string]: optionItem } = {
  other: { label: "Autre", value: "x" },
  none: { label: "Aucun", value: "y" },
  unknown: { label: "Je ne sais pas", value: "z" },
};

export const FIELDS: fieldItem[] = [
  {
    name: "seeding",
    label: "Semis ou sur-semis ?",
    options: [
      { label: "Oui", value: "a" },
      { label: "Non", value: "b" },
      defaultOption.unknown,
    ],
    enable: true
  },
  {
    name: "cuttingType",
    label: "Type de fauche",
    options: [
      { label: "Fauche broyée", value: "a" },
      { label: "Fauche coupée", value: "b" },
      { label: "Pas de fauche", value: "c" },
      defaultOption.unknown,
    ],
    enable: true
  },
  {
    name: "cuttingPeriod",
    label: "Période des fauches",
    options: [
      { label: "Estivale (juin-août)", value: "a" },
      { label: "Précoce (janvier-mai)", value: "b" },
      { label: "Tardive (septembre-décembre)", value: "c" },
      defaultOption.unknown,
    ],
    enable: true
  },
  {
    name: "cuttingFrequency",
    label: "Fréquence des fauches",
    options: [
      { label: "< 1 an", value: "a" },
      { label: "> 2/an", value: "b" },
      { label: "1/an", value: "c" },
      { label: "2/an", value: "c" },
      defaultOption.unknown,
    ],
    enable: true
  },
  {
    name: "residueExportation",
    label: "Exportation des résidus",
    options: [
      { label: "Oui", value: "a" },
      { label: "Non", value: "b" },
      defaultOption.unknown,
    ],
    enable: true
  },
  {
    name: "pastureType",
    label: "type de pâturage",
    options: [
      { label: "Bovin", value: "a" },
      { label: "Caprin", value: "b" },
      { label: "Equidé", value: "c" },
      { label: "Ovin", value: "d" },
      { label: "Pas de pâturage", value: "e" },
      defaultOption.unknown,
      defaultOption.other,
    ],
    enable: true
  },
  {
    name: "pasturePressure",
    label: "Pression de pâturage (en nombre d'animaux)",
    enable: true
  },
  {
    name: "pastureSize",
    label: "Surface pâturée par an (en m2)",
    enable: true
  },
  {
    name: "pastureDuration",
    label: "Durée annuelle du pâturage (en nombre de mois)",
    enable: true
  },
  {
    name: "phytosanitary",
    label: "Traitement phytosanitaire",
    options: [
      defaultOption.none,
      { label: "Anti-limaces", value: "a" },
      { label: "Anti-mousses", value: "b" },
      { label: "Fongicide", value: "c" },
      { label: "Herbicide", value: "d" },
      defaultOption.unknown,
      defaultOption.other,
    ],
    enable: true
  },
  {
    name: "uncontrolledManagementPressure",
    label: "Pression de gestion non-contrôlée",
    options: [
      { label: "Cueillette", value: "a" },
      { label: "Incendies", value: "b" },
      { label: "Pâturage et terriers par les lapins et rongeurs", value: "c" },
      { label: "Travaux sur la parcelle", value: "d" },
      defaultOption.none,
      defaultOption.unknown,
      defaultOption.other,
    ],
    enable: true
  },
];

interface IProps {
  formApi: any;
}

const CreateManagementPractices: FC<IProps> = (props) => {
  const { formApi } = props;
  const [fields, setFields] = useState<fieldItem[]>(FIELDS);

  const onSelectChange = (fieldName: string, value: any) => {
    if (fieldName == "cuttingType") {
      const newFields: fieldItem[] = fields.map<fieldItem>((f: fieldItem) => {
        if (["cuttingPeriod", "cuttingFrequency", "residueExportation"].includes(f.name))
          f.enable = !["c", "z"].includes(value)
        return f
      })
      setFields(newFields)
    }
    if (fieldName == "pastureType") {
      const newFields: fieldItem[] = fields.map<fieldItem>((f: fieldItem) => {
        if (["pasturePressure", "pastureSize", "pastureDuration"].includes(f.name))
          f.enable = !["e", "z"].includes(value)
        return f
      })
      setFields(newFields)
    }
    if (fieldName != "cuttingType") {
      return;
    }
    fields.forEach((field) => {
      if (["cuttingPeriod", "cuttingFrequency"].includes(field.name)) {
        field.facultative = value === "c";
      }
    });
    setFields([...fields]);
  };

  return (
    <Stack spacing={2} mt={2}>
      {fields.filter((selectField: fieldItem) => selectField.enable).map((selectField: fieldItem) =>
        selectField?.options ? (
          <FormControl key={selectField.name} fullWidth>
            <InputLabel>{selectField.label}</InputLabel>
            <Controller
              defaultValue={""}
              name={selectField.name as keyof SiteForm}
              control={formApi.control}
              render={({ field: { onChange, value } }) => (
                <Select
                  {...formApi.register(selectField.name as keyof SiteForm)}
                  required={!selectField.facultative}
                  error={
                    !!formApi.formState.errors[
                    selectField.name as keyof SiteForm
                    ]
                  }
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    onSelectChange(selectField.name, e.target.value);
                  }}
                  label={selectField.label}
                >
                  {selectField?.options?.map((option, i) => {
                    return (
                      <MenuItem key={i} value={option.value}>
                        {option.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
            />
          </FormControl>
        ) : (
          <Controller
            key={selectField.name}
            defaultValue={0}
            name={selectField.name as keyof SiteForm}
            control={formApi.control}
            render={({ field: { onChange, value } }) => (
              <TextField
                {...formApi.register(selectField.name as keyof SiteForm)}
                required
                value={value}
                type="number"
                onChange={(e) => {
                  onChange(e);
                }}
                label={selectField.label}
                inputProps={{ min: 0 }}
              />
            )}
          />
        )
      )}
    </Stack>
  );
};

export default CreateManagementPractices;
