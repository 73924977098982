/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DeviceRead } from '../models/DeviceRead';
import type { DeviceReadAll } from '../models/DeviceReadAll';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DevicesService {

    /**
     * All Devices
     * @param query
     * @returns DeviceReadAll Successful Response
     * @throws ApiError
     */
    public static allDevicesDevicesGet(
        query?: Array<string>,
    ): CancelablePromise<DeviceReadAll> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/devices/',
            query: {
                'query': query,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create One Device
     * @returns DeviceRead Successful Response
     * @throws ApiError
     */
    public static createOneDeviceDevicesPost(): CancelablePromise<DeviceRead> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/devices/',
        });
    }

}