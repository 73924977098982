import { FC } from "react";
import { Box, InputAdornment, Paper, Stack, TextField } from "@mui/material";
import IconSearch from "@mui/icons-material/Search";
import CommonsMultiSelect from "../commons/MultiSelect";
import CommonsDatePicker from "../commons/DatePicker";
import ContainerTitle from "../commons/ContainerTitle";
import useStore from "../../store/useStore";
import { SessionFilter } from "../../store/createSessions";

interface SessionFiltersProps {
  onChange?(filters: SessionFilter): void;
}

const SessionFilters: FC<SessionFiltersProps> = ({ onChange }) => {
  const { sites, sessionFilter, devices, sessionUsers, setSessionFilter } =
    useStore((state) => state);

  const handleUUIDChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: string
  ) => {
    const value = e.currentTarget.value;
    const newSessionFilter = {
      ...sessionFilter,
      currentPage: 0,
      [type]: value,
    };
    setSessionFilter(newSessionFilter);
    onChange && onChange(newSessionFilter);
  };

  const handleMultiSelectChange = (type: string, value: any[]) => {
    const newSessionFilter = {
      ...sessionFilter,
      currentPage: 0,
      [type]: value,
    };
    setSessionFilter(newSessionFilter);
    onChange && onChange(newSessionFilter);
  };

  const handleOnStartDateChange = (date: Date | null) => {
    const newSessionFilter = {
      ...sessionFilter,
      currentPage: 0,
      startDate: date ?? undefined,
    };
    setSessionFilter(newSessionFilter);
    onChange && onChange(newSessionFilter);
  };

  const handleOnEndDateChange = (date: Date | null) => {
    const newSessionFilter = {
      ...sessionFilter,
      currentPage: 0,
      endDate: date ?? undefined,
    };
    setSessionFilter(newSessionFilter);
    onChange && onChange(newSessionFilter);
  };

  return (
    <Paper>
      <ContainerTitle title="filtres">
        <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
          <TextField
            fullWidth
            placeholder="Chercher une session"
            onChange={(e) => handleUUIDChange(e, "uuid")}
            value={sessionFilter.uuid ?? ""}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconSearch />
                </InputAdornment>
              ),
            }}
          />
          <Box sx={{ width: "100%" }}>
            <CommonsDatePicker
              startDate={sessionFilter.startDate}
              endDate={sessionFilter.endDate}
              onStartDateChange={handleOnStartDateChange}
              onEndDateChange={handleOnEndDateChange}
            />
          </Box>
          <CommonsMultiSelect
            label="Sites"
            options={sites.records}
            optionLabel="name"
            optionValue="id"
            value={sessionFilter.sites}
            onChange={(values) => handleMultiSelectChange("sites", values)}
          />
          <CommonsMultiSelect
            label="Utilisateurs"
            options={sessionUsers.records}
            optionLabel="owner_info"
            optionValue="owner_id"
            value={sessionFilter.users}
            onChange={(values) => handleMultiSelectChange("users", values)}
          />
          <CommonsMultiSelect
            label="Dispositifs"
            options={devices.records}
            optionLabel="uuid"
            optionValue="id"
            value={sessionFilter.devices}
            onChange={(values) => handleMultiSelectChange("devices", values)}
          />
        </Stack>
      </ContainerTitle>
    </Paper>
  );
};

export default SessionFilters;
