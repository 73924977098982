import { FC, useState, useCallback, useEffect } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Button,
  Menu,
  MenuItem,
  Stack,
  Typography,
  Container,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PersonIcon from "@mui/icons-material/Person";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { styled } from "@mui/material/styles";
import { useKeycloak } from "@react-keycloak/web";
import { KeycloakTokenParsed } from "keycloak-js";
import useStore from "../../store/useStore";
import { useNavigate } from "react-router-dom";

const Offset = styled("div")(({ theme }) => theme.mixins.toolbar);

const menuTools = [
  { title: "Tableau de bord", link: "/user" },
  { title: "Gestion des sites", link: "/sites" },
  { title: "Gestion des sessions", link: "/sessions" },
  { title: "Galerie", link: "/gallery" },
  { title: "Calendrier", link: "/calendar" },
];

const styles = {
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  smooth: {
    transition: ".5s all ease-in-out",
  },
  logoContainer: {
    position: "relative",
    overflow: "show",
    cursor: "pointer",
  },
  logo: {
    width: "100%",
    backgroundSize: "contain",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundImage: "url(/assets/logo.svg)",
    zIndex: 1000,
    position: "absolute",
  },
  groupName: {
    display: { xs: "none", md: "flex" },
    alignSelf: "center",
    flex: 1,
    justifyContent: "center",
  },
};

const smallSize = "30px";
const bigSize = "100px";

type ParsedToken = KeycloakTokenParsed & {
  email?: string;
  preferred_username?: string;
  given_name?: string;
  family_name?: string;
};

interface AppLogoProps {
  smallLogo?: boolean;
}

const AppLogo: FC<AppLogoProps> = ({ smallLogo }) => {
  const navigate = useNavigate();
  const [scrolled, setScrolled] = useState(false);

  const handleGoHome = () => {
    navigate("/");
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () =>
        setScrolled(window.pageYOffset > 200)
      );
    }
  }, [navigate, smallLogo]);

  return (
    <Stack direction="row">
      <Box
        sx={[
          {
            width:
              scrolled || smallLogo
                ? smallSize
                : { md: bigSize, xs: smallSize },
          },
          styles.logoContainer,
          styles.smooth,
        ]}
      >
        <Box
          onClick={() => {
            setScrolled(!scrolled);
          }}
          sx={[
            {
              height:
                scrolled || smallLogo
                  ? smallSize
                  : { md: bigSize, xs: smallSize },
            },
            styles.logo,
            styles.smooth,
          ]}
        />
      </Box>
      <Typography
        onClick={handleGoHome}
        color="secondary"
        variant="h4"
        sx={[
          {
            pl: 3,
            cursor: "pointer",
            fontSize:
              scrolled || smallLogo ? "1.5rem" : { md: "2rem", xs: "1.5rem" },
          },
          styles.smooth,
        ]}
      >
        LÉPINOC
      </Typography>
    </Stack>
  );
};

const ToolsMenu: FC = () => {
  const navigate = useNavigate();
  const [anchorElTools, setAnchorElTools] = useState<null | HTMLElement>(null);
  const openTools = Boolean(anchorElTools);
  const { keycloak } = useKeycloak();

  const handleClickTools = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElTools(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorElTools(null);
  };

  return (
    <>
      <Button
        color="inherit"
        id="tools-button"
        aria-controls={openTools ? "tools-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={openTools ? "true" : undefined}
        onClick={handleClickTools}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Menu
      </Button>
      <Menu
        id="tools-menu"
        anchorEl={anchorElTools}
        open={openTools}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "tools-button",
        }}
      >
        {keycloak.authenticated && [
          <MenuItem
            key="accueil"
            onClick={() => {
              handleClose();
              navigate("/");
            }}
          >
            Accueil
          </MenuItem>,
          menuTools.map((menuItem) => (
            <MenuItem
              key={menuItem.link}
              onClick={() => {
                handleClose();
                navigate(menuItem.link);
              }}
            >
              {menuItem.title}
            </MenuItem>
          )),
        ]}

        <MenuItem
          target="_blank"
          component="a"
          href="https://noe.org/programme-lepinoc#titre-outils"
        >
          Ressources
        </MenuItem>
        <MenuItem
          target="_blank"
          component="a"
          href="https://noe.org/media/missions/noe-faq-lepinoc-2023.pdf"
        >
          FAQ
        </MenuItem>
      </Menu>
    </>
  );
};

const UserMenu: FC = () => {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const openUser = Boolean(anchorElUser);

  const handleClickUser = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorElUser(null);
  };

  const { keycloak } = useKeycloak();
  const parsedToken: ParsedToken | undefined = keycloak?.tokenParsed;

  const handleLogin = useCallback(() => {
    keycloak?.login({
      redirectUri: `${window.location.origin}/user`,
    });
  }, [keycloak]);

  const handleShowProfile = useCallback(() => {
    if (keycloak) {
      window.location.href = keycloak.createAccountUrl();
    }
  }, [keycloak]);

  return keycloak.authenticated ? (
    <Box pl={8}>
      <Button
        color="inherit"
        id="user-button"
        aria-controls={openUser ? "user-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={openUser ? "true" : undefined}
        onClick={handleClickUser}
        startIcon={<PersonIcon color="primary" />}
      >
        {parsedToken?.preferred_username}
      </Button>
      <Menu
        id="user-menu"
        anchorEl={anchorElUser}
        open={openUser}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "user-button",
        }}
      >
        <MenuItem onClick={handleShowProfile}>Mon compte</MenuItem>
        <MenuItem
          onClick={async () => {
            localStorage.clear();
            await keycloak.logout({
              redirectUri: `${window.location.origin}/`,
            });
          }}
        >
          Se déconnecter
        </MenuItem>
      </Menu>
    </Box>
  ) : (
    <Button
      startIcon={<PersonOutlineOutlinedIcon color="primary" />}
      color="inherit"
      onClick={handleLogin}
      sx={{ whiteSpace: "nowrap", minWidth: "auto" }}
    >
      <Box sx={{ display: { xs: "none", md: "block" } }}>se connecter</Box>
    </Button>
  );
};

interface HeaderProps {
  smallLogo?: boolean;
}

const Header: FC<HeaderProps> = ({ smallLogo = false }) => {
  const user = useStore((state) => state.user);
  return (
    <>
      <AppBar sx={{ bgcolor: "primary.dark" }} position="fixed" elevation={0}>
        <Toolbar sx={styles.toolbar}>
          <Container sx={{ display: "flex", alignItems: "center" }}>
            <AppLogo smallLogo={smallLogo} />
            <Typography sx={styles.groupName}>
              {user?.groups[0]?.name
                ? `Organisme : ${user?.groups[0].name}`
                : ""}
            </Typography>
          </Container>
          <Stack direction="row" spacing={2}>
            <ToolsMenu />
            <UserMenu />
          </Stack>
        </Toolbar>
      </AppBar>
      <Offset />
    </>
  );
};

export default Header;
