import { FC, ReactElement, ReactNode } from "react";
import { styled } from "@mui/material/styles";

interface LayoutMapProps {
  map: ReactElement;
  children: ReactNode;
}

const LayoutMapContainer = styled("div")({
  flexGrow: 1,
  display: "grid",
  gridTemplateColumns: "repeat(12, 1fr)",
  columnGap: "1rem",
  rowGap: "1rem",
});

const LayoutMapMap = styled("div")(({ theme }) => ({
  gridColumn: "1/8",
  [theme.breakpoints.down("md")]: {
    gridColumn: "1/13",
    gridRow: "1/5",
  },
}));

const LayoutMapChildren = styled("div")(({ theme }) => ({
  gridColumn: "8/13",
  [theme.breakpoints.down("md")]: {
    gridColumn: "1/13",
    gridRow: "5/9",
  },
  overflowY: "scroll",
}));

const LayoutMap: FC<LayoutMapProps> = ({ map, children }) => {
  return (
    <LayoutMapContainer>
      <LayoutMapMap>{map}</LayoutMapMap>
      <LayoutMapChildren>{children}</LayoutMapChildren>
    </LayoutMapContainer>
  );
};

export default LayoutMap;
