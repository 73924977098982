import { FC, MutableRefObject, useEffect, useState } from "react";
import { Polygon } from "react-leaflet";
import LayoutMap from "../components/layout/Map";
import SiteList from "../components/site/List";
import SiteAdd from "../components/site/Add";
import SiteInfo from "../components/site/Info";
import MapBase from "../components/map/Base";
import useStore from "../store/useStore";
import { useNavigate, useParams } from "react-router-dom";
import { LatLngExpression } from "leaflet";
import { SiteRead } from "../client";
import { SiteReadWithSessionUUID } from "../client/models/SiteReadWithSessionUUID";
import { getDepartment } from "../lib/geoApiGouv";
import { SiteTemporal } from "../store/createSites";
import L from "leaflet";
import { List, ListItemButton, Paper, Popover } from "@mui/material";
import CommonAlertDialog, {
  DialogState,
} from "../components/commons/AlertDialog";

export interface SiteDialogState extends DialogState {
  siteToDelete?: SiteRead;
}
const defaultDialogState = {
  open: false,
  loading: false,
  siteToDelete: undefined,
};

const SitesPage: FC = () => {
  const navigate = useNavigate();
  const params = useParams();

  const {
    sites,
    site,
    sitesGet,
    setSite,
    setSiteTemporal,
    siteGet,
    deleteSite,
    groupGetAll,
  } = useStore((state) => state);

  const [selectedSite, setSelectedSite] = useState<string | undefined>(
    undefined
  );
  const [markerPosition, setMarkerPosition] = useState({ x: 0, y: 0 });
  const [markers, setMarkers] = useState([]);
  const [markersPopupOpen, setMarkersPopupOpen] = useState(false);
  const [mapRefs, setMapRefs] = useState<{
    map: L.Map;
    featureGroup: MutableRefObject<L.FeatureGroup>;
  }>();
  const [dialogState, setDialogState] =
    useState<SiteDialogState>(defaultDialogState);

  useEffect(() => {
    const fetchData = async () => {
      await groupGetAll();

      if (params.siteId && params.siteId !== "new") {
        await siteGet(params.siteId);
        setSiteTemporal(undefined);
      } else {
        setSite(undefined);
      }
    };

    fetchData();
  }, [params.siteId]);

  useEffect(() => {
    if (params.siteId && site && site.sessions && site?.sessions?.length > 0) {
      const cluster = L.markerClusterGroup({
        removeOutsideVisibleBounds: true,
        maxClusterRadius: 120,
        zoomToBoundsOnClick: false,
      });

      cluster.on("clusterclick", (c: any) => {
        const newMarkers = c.layer
          .getAllChildMarkers()
          .sort((a: any, b: any) => {
            if (a.options.title < b.options.title) {
              return 1;
            }

            if (a.options.title > b.options.title) {
              return -1;
            }

            return 0;
          });
        setMarkers(newMarkers);
        setMarkersPopupOpen(newMarkers.length > 0);
        setMarkerPosition(c.containerPoint);
      });

      for (const session of site.sessions) {
        const layer = L.marker(
          session.geom?.coordinates.reverse() as LatLngExpression,
          {
            title: session.sunset_date,
            alt: session.uuid,
          }
        );

        layer.addEventListener("click", () => {
          navigate(`/sessions/${session.uuid}`);
        });
        cluster.addLayer(layer);
      }

      mapRefs?.map.addLayer(cluster);
    }

    const featureGroup = mapRefs?.featureGroup?.current;

    if (featureGroup && featureGroup.getBounds().isValid()) {
      mapRefs?.map.fitBounds(featureGroup.getBounds());
    }
  }, [mapRefs, sites, site, params]);

  const handleOnInit = (
    map: L.Map,
    featureGroup: MutableRefObject<L.FeatureGroup>
  ) => {
    setMapRefs({
      map,
      featureGroup,
    });
  };

  const handleCreateSite = async (newSiteTemporal: SiteTemporal) => {
    const [lat, lon] = newSiteTemporal.centroid.coordinates;

    newSiteTemporal.department = await getDepartment(lon, lat);
    setSiteTemporal(newSiteTemporal);
    navigate(`/sites/new`);
  };

  const handleSelected = (site: SiteRead) => {
    setSelectedSite(site.uuid);
  };

  const handleDeleted = async (site: SiteRead) => {
    // await deleteSite(site);
    setDialogState({ ...dialogState, siteToDelete: site, open: true });
  };

  const handleAgree = async () => {
    if (dialogState.siteToDelete) {
      setDialogState({ ...dialogState, loading: true });
      await deleteSite(dialogState.siteToDelete);
    }
    handleDisagree();
  };

  const handleDisagree = () => {
    setDialogState(defaultDialogState);
  };

  return (
    <LayoutMap
      map={
        <MapBase
          editable={params.siteId === undefined}
          onInit={handleOnInit}
          onCreate={handleCreateSite}
        >
          {!params.siteId &&
            sites?.records.map((record: SiteReadWithSessionUUID) => (
              <Polygon
                key={record.uuid}
                pathOptions={{
                  color: record.uuid === selectedSite ? "#1E1D4B" : "#4BAFE8",
                }}
                positions={record.geom.coordinates[0].map(
                  (a: LatLngExpression[]) => a.slice().reverse()
                )}
                eventHandlers={{
                  click: () => {
                    navigate(`/sites/${record.uuid}`);
                  },
                }}
              />
            ))}
          {site?.uuid && (
            <Polygon
              pathOptions={{
                color: "#1E1D4B",
              }}
              positions={site.geom.coordinates[0].map((a: LatLngExpression[]) =>
                a.slice().reverse()
              )}
              eventHandlers={{
                click: () => {
                  navigate(`/sites/${site.uuid}`);
                },
              }}
            />
          )}
          <Popover
            open={markersPopupOpen}
            anchorReference="anchorPosition"
            anchorPosition={{ top: markerPosition.y, left: markerPosition.x }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            onClose={() => setMarkersPopupOpen(false)}
          >
            <Paper
              style={{
                maxHeight: 300,
                overflowY: "scroll",
                overflowX: "hidden",
              }}
            >
              <List>
                {markers.map((marker: any) => (
                  <ListItemButton
                    onClick={() => {
                      navigate(`/sessions/${marker.options.alt}`);
                    }}
                    key={marker.options.alt}
                  >
                    {new Date(marker.options.title!).toLocaleString()}
                  </ListItemButton>
                ))}
              </List>
            </Paper>
          </Popover>
        </MapBase>
      }
    >
      {params.siteId === undefined && (
        <SiteList onSelected={handleSelected} onDeleted={handleDeleted} />
      )}
      {params.siteId === "new" && (
        <SiteAdd onCancel={() => navigate(`/sites`)} />
      )}
      {params.siteId && params.siteId !== "new" && (
        <SiteInfo onCancel={() => navigate(`/sites`)} />
      )}
      <CommonAlertDialog
        title={"Attention !"}
        content={[
          "Supprimer ce site supprimera toutes les sessions associées et leurs images",
        ]}
        agreeLabel={"Oui je suis sûr"}
        disagreeLabel={"Non, j'ai changé d'avis"}
        open={dialogState.open}
        loading={dialogState.loading}
        onAgree={handleAgree}
        onDisagree={handleDisagree}
      />
    </LayoutMap>
  );
};

export default SitesPage;
