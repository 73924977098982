import { List, ListItem, ListItemText } from "@mui/material";
import useStore from "../../store/useStore";
import { translations } from "../../intl/translations";

const SessionSatisfactionList = () => {
  const { session } = useStore((state) => state);
  const sessionSatisfactionKeys = Object.keys(
    translations.fr.translation.satisfaction
  );

  return (
    <List sx={{ width: "100%" }}>
      {sessionSatisfactionKeys.map(
        (key) =>
          session?.form.satisfaction[key] && (
            <ListItem dense key={key} alignItems="flex-start">
              <ListItemText
                primary={
                  translations.fr.translation.satisfaction[
                    key as keyof typeof translations.fr.translation.satisfaction
                  ]
                }
                secondary={session?.form.satisfaction[key]}
              />
            </ListItem>
          )
      )}
    </List>
  );
};

export default SessionSatisfactionList;
