import { useEffect, useState } from "react";
import buildHasuraProvider, {
  BuildFields,
  FetchType,
  buildFields,
} from "ra-data-hasura";
import { buildVariables } from "ra-data-hasura/dist/buildVariables";
import { buildGetListVariables } from "ra-data-hasura/dist/buildVariables/buildGetListVariables";
import { Admin, DataProvider, Resource } from "react-admin";
import { AdminSiteList } from "../components/admin/AdminSiteList";
import gql from "graphql-tag";
import { AdminSessionList } from "../components/admin/AdminSessionList";
import { AdminDeviceList } from "../components/admin/AdminDeviceList";
import { get, set } from "lodash";
import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { AdminSiteShow } from "../components/admin/AdminSiteShow";
import { AdminSessionShow } from "../components/admin/AdminSessionShow";

const extractFieldsFromQuery = (queryAst: any) => {
  return queryAst.definitions[0].selectionSet.selections;
};

const EXTENDED_GET_SITE = gql`
  {
    owner {
      id
      username
    }
    shareds {
      group {
        id
        name
      }
    }
  }
`;

const EXTENDED_GET_SITES = gql`
  {
    owner {
      id
      username
    }
    shareds {
      group {
        id
        name
      }
    }
  }
`;

const EXTENDED_GET_SESSIONS = gql`
  {
    device {
      id
      owner {
        id
        username
      }
      uuid
    }
    group {
      id
      name
    }
    shareds {
      group {
        id
        name
      }
    }
    site {
      id
      name
    }
    owner {
      id
      username
    }
    view {
      sunset_time
    }
  }
`;

const EXTENDED_GET_SESSION = gql`
  {
    device {
      id
      owner {
        id
        username
      }
      uuid
    }
    group {
      id
      name
    }
    shareds {
      group {
        id
        name
      }
    }
    site {
      id
      name
    }
    owner {
      id
      username
    }
    view {
      sunset_time
    }
    images {
      id
      filename
      created_date
      checksum
      extension
      exif
    }
  }
`;

const EXTENDED_GET_DEVICES = gql`
  {
    owner {
      id
      username
    }
    group {
      id
      name
    }
  }
`;

const customBuildFields: BuildFields = (type, fetchType) => {
  const resourceName = type.name;

  // First take the default fields (all, but no related or nested).
  const defaultFields = buildFields(type, fetchType);

  if (resourceName === "site" && fetchType === "GET_LIST") {
    const relatedEntities = extractFieldsFromQuery(EXTENDED_GET_SITES);
    defaultFields.push(...(relatedEntities as any));
  }

  if (resourceName === "site" && fetchType === "GET_ONE") {
    const relatedEntities = extractFieldsFromQuery(EXTENDED_GET_SITE);
    defaultFields.push(...(relatedEntities as any));
  }

  if (resourceName === "session" && fetchType === "GET_LIST") {
    const relatedEntities = extractFieldsFromQuery(EXTENDED_GET_SESSIONS);
    defaultFields.push(...(relatedEntities as any));
  }

  if (resourceName === "session" && fetchType === "GET_ONE") {
    const relatedEntities = extractFieldsFromQuery(EXTENDED_GET_SESSION);
    defaultFields.push(...(relatedEntities as any));
  }

  if (resourceName === "device" && fetchType === "GET_LIST") {
    const relatedEntities = extractFieldsFromQuery(EXTENDED_GET_DEVICES);
    defaultFields.push(...(relatedEntities as any));
  }

  return defaultFields;
};

const httpLink = createHttpLink({
  uri: "/api/v1/graphql/",
});

const authLink = setContext(async (_, { headers }) => {
  const token = localStorage.getItem("lepinoc-access-token");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

export const RAdmin = () => {
  const [dataProvider, setDataProvider] = useState<DataProvider | null>(null);

  useEffect(() => {
    const buildDataProvider = async () => {
      const apolloAuthClientWithAuth = new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache(),
      });
      const dataProvider: DataProvider = await buildHasuraProvider(
        {
          client: apolloAuthClientWithAuth,
        },
        {
          buildFields: customBuildFields,
        },
        (introspectionResults) =>
          (resource, aorFetchType, params, queryType) => {
            const vars =
              buildVariables(introspectionResults)(
                resource,
                aorFetchType,
                params,
                queryType
              ) || {};
            if (
              aorFetchType === FetchType.GET_LIST &&
              typeof params.filter?.["habitats@_is_null"] !== "undefined"
            ) {
              if (!vars.where?._and?.[0]) {
                set(vars, "where._and", []);
              }
              const filters: any[] = vars.where._and;
              let filter = filters.find((f) =>
                Object.keys(f).includes("habitats")
              );
              if (!filter) {
                filter = {};
                filters.push(filter);
              }
              if (!get(filter, "habitats._is_null")) {
                set(
                  filter,
                  "habitats._is_null",
                  params.filter["habitats@_is_null"]
                );
              }
            }

            return vars;
          }
      );

      setDataProvider(() => dataProvider);
    };
    buildDataProvider();
  }, []);

  if (!dataProvider) return <p>Loading...</p>;

  return (
    <Admin basename="/admin" dataProvider={dataProvider}>
      <Resource name="site" list={AdminSiteList} show={AdminSiteShow} />
      <Resource
        name="session"
        list={AdminSessionList}
        show={AdminSessionShow}
      />
      <Resource name="device" list={AdminDeviceList} />
    </Admin>
  );
};
