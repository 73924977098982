import { NavigateFunction } from "react-router-dom";

export const handleGoBack = (
  defaultRoute: string,
  navigate: NavigateFunction
) => {
  window.history.state && window?.history?.state?.idx > 0
    ? navigate(-1)
    : navigate(defaultRoute);
};
